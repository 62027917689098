import React, { useEffect, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import { Layout, Typography, Tag, Button, message, Spin, Popconfirm, Space, Progress } from 'antd';
import { ReloadOutlined, FullscreenOutlined } from '@ant-design/icons';
import moment from 'moment';

import request from '../components/request';

import '../less/map.less';
import EvoMap from '../components/mapbox';
import { useFullscreen } from 'ahooks';

const { Paragraph, Text } = Typography;
const { Content } = Layout;

// const getDataSource = (recover, setDataSource) => {
//     setDataSource({
//         center: [114.00656, 22.604046],
//         paths: [
//             {
//                 driver: {
//                     name: '北辰哥',
//                     carNumber: '粤B-V587NB',
//                     vehicleType: '五菱宏光',
//                     phoneNumber: '1XXXXXXXXXX',
//                     position: [114.000391 + Math.random() * 0.01, 22.590835]
//                 },
//                 routes: [
//                     {
//                         name: '南方科技大学一号门',
//                         position: [113.999442 + Math.random() * 0.001, 22.592821],
//                         description: '这是一段描述实例，可以在此处放置此地标的详细信息，应来自与数据库或金蝶系统。'
//                     },
//                     {
//                         name: '塘朗城广场西区',
//                         position: [113.998530 + Math.random() * 0.001, 22.588814],
//                         description: '这是一段描述实例，可以在此处放置此地标的详细信息，应来自与数据库或金蝶系统。'
//                     },
//                     {
//                         name: '津品汤包',
//                         position: [113.995633 + Math.random() * 0.001, 22.585298],
//                         description: '这是一段描述实例，可以在此处放置此地标的详细信息，应来自与数据库或金蝶系统。'
//                     }
//                 ]
//             },
//             {
//                 driver: {
//                     name: '旭坤哥',
//                     carNumber: '粤B-114514',
//                     vehicleType: '老头乐疯牛款',
//                     phoneNumber: '1YYYYYYYYYY',
//                     position: [113.986744, 22.585129]
//                 },
//                 routes: [
//                     {
//                         name: '南方科技大学五号门',
//                         position: [113.994681, 22.602187],
//                         description: '这是一段描述实例，可以在此处放置此地标的详细信息，应来自与数据库或金蝶系统。'
//                     },
//                     {
//                         name: '雀粤食苑',
//                         position: [113.988938, 22.591974],
//                         description: '这是一段描述实例，可以在此处放置此地标的详细信息，应来自与数据库或金蝶系统。'
//                     },
//                     {
//                         name: '博林天瑞轩',
//                         position: [113.972126, 22.582658],
//                         description: '这是一段描述实例，可以在此处放置此地标的详细信息，应来自与数据库或金蝶系统。'
//                     }
//                 ]
//             },
//         ]
//     });
//     recover();
// }

const getDataSource = (recover, setDataSource) => request(
    `/superuser/data_source`, 'POST', {},
    (data) => {
        if (data?.status === 1) {
            setDataSource(data?.dataSource);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const doAlgorithm = (recover, onSucceed) => request(
    `/superuser/algorithm`, 'POST', {},
    (data) => {
        if (data?.status === 1) {
            message.success("算法已成功开始运行，请稍候刷新查看结果");
            onSucceed();
        }
        else if (data?.status === 2) {
            message.info("算法正在执行中，请稍候");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const getProgress = (onSucceed) => request(
    `/algorithm`, 'POST', {},
    (data) => {
        if (data?.stage === 2 && data?.percentage === 100) {
            onSucceed({ stage: 3, percentage: 100 });
        }
        else {
            onSucceed(data);
            setTimeout(() => getProgress(onSucceed), 1000);
        }
    },
    (error) => message.error("网络错误"),
    () => { }
);

const getStageText = (stage) => {
    switch (stage) {
        case -1:
            return "未运行";
        case 0:
            return "数据准备阶段";
        case 1:
            return "线路生成阶段";
        case 2:
            return "线路优化阶段";
        case 3:
            return "已完成";
        default:
            return "未知阶段";
    }
};

const defaultCenter = [114.00656, 22.604046];

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [refreshTime, setRefreshTime] = useState(moment());
    const [queryLoading, setQueryLoading] = useState(false);
    const [center, setCenter] = useState(defaultCenter);
    const [paths, setPaths] = useState([]);

    const [doLoading, setDoLoading] = useState(false);
    const [progressVisible, setProgressVisible] = useState(false);
    const [progress, setProgress] = useState({ stage: -1, percentage: 0 });

    const [, mapControl] = useFullscreen(() => document.getElementById('map-container'));

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataSource(
            () => setQueryLoading(false),
            data => {
                setPaths(data?.paths || []);
                setCenter(data?.center || defaultCenter);
                setRefreshTime(moment());
            }
        );
    }, [updateSign]);

    const getStatus = () => {
        if (isNaN(progress?.percentage)) {
            return 'exception';
        } else if (progress?.percentage === 100) {
            return 'success';
        } else {
            return 'active';
        }
    };

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    您可以在此页面查看<Text strong>当日</Text>所有经由排线算法处理后的运单的运送路线。
                    后端服务器将在每日固定的时间对下一天新增的订单进行自动排线，如果您需要立即手动执行排线算法，可以点击页头右上角的“<Text strong>立即排线</Text>”按钮执行算法。
                </Paragraph>
                <Paragraph>
                    点击页头右上角的“<Text strong>刷新</Text>”按钮可以重新加载地图内容。
                    您可以关注页头上方的蓝色“<Text strong>刷新时间</Text>”标签以判断当前数据的时效性。
                </Paragraph>
            </Typography>}
            subTitle="当前已排线车辆的路径示意图"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            extra={[
                progressVisible &&
                <Progress
                    percent={progress?.percentage}
                    strokeWidth={16}
                    status={getStatus()}
                    format={percent => `${getStageText(progress?.stage)}：${percent}%`}
                />,
                <Popconfirm
                    placement="bottomRight"
                    title={<Space direction="vertical">
                        <Text strong>确认立即执行排线算法？</Text>
                        <Text>将当前所有未执行排线的订单输入排线算法进行排线，将需要一定的时间；<br />若算法已在执行，将不能再次执行排线算法。</Text>
                    </Space>}
                    onConfirm={() => {
                        setDoLoading(true);
                        doAlgorithm(() => setDoLoading(false), () => {
                            setProgressVisible(true);
                            getProgress(newProgress => setProgress(newProgress));
                        });
                    }}
                    okText="确认排线"
                    cancelText="取消"
                    disabled={queryLoading}
                />,
                <Button
                    icon={<FullscreenOutlined />}
                    onClick={() => mapControl.enterFullscreen()}
                >
                    全屏显示
                </Button>,
                <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => setUpdateSign(!updateSign)}
                    loading={queryLoading}
                >
                    刷新
                </Button>,
            ]}
        >
            <Spin
                spinning={queryLoading}
                size="large"
                tip="请求地图资源中，请稍候"
            >
                <Content style={{
                    background: 'white',
                    height: '1024px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden'
                }} id='map-container'>
                    <EvoMap
                        center={center}
                        paths={paths}
                        refreshData={() => setUpdateSign(!updateSign)}
                    />
                </Content>
            </Spin>
        </PageContainer>
    );
}