import React, { useEffect, useState } from 'react';
import { Divider, Modal, message, Space, Drawer, Button, Layout, Spin, Typography, Tooltip, Input } from 'antd';
import { CheckCircleOutlined, WarningOutlined, LinkOutlined, AuditOutlined, InfoCircleOutlined, FormOutlined } from '@ant-design/icons';
import ProDescriptions from '@ant-design/pro-descriptions';

import request from './request';

const { Content } = Layout;
const { Paragraph, Text } = Typography;

const getOrderInfo = (info, recover, setData, onFailed) => request(
    '/superuser/get/order_detail', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
            onFailed();
        }
    },
    (error) => {
        message.error("网络错误");
        onFailed();
    },
    recover
)

export const refreshOrderList = (info, recover, onSucceed) => request(
    '/superuser/refresh_orders', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("手动拉取订单成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

export const setOrderAbnormal = (info, recover, onSucceed) => request(
    '/superuser/set/order_abnormal', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success(data?.abnormal === 0 ? "取消异常成功" : "设为异常成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

export const OrderPlannedModal = ({
    info = { id: '', waybill: '' },
    visible,
    onSucceed = () => {},
    onClose = () => {}
}) => {
    const [orderId, setOrderId] = useState(info?.id);
    const [waybill, setWaybill] = useState(info?.waybill);

    const [loading, setLoading] = useState(false);

    const setOrderWaybill = () => request(
        '/waybill/update_order_waybill', 'POST', {
            orderId: orderId, waybillId: waybill
        }, (data) => {
            message.success("更改所属运单成功");
            onClose();
            onSucceed();
        },
        (error) => {
            message.error("网络错误");
            setLoading(false);
        },
        () => setLoading(false)
    );

    useEffect(() => {
        setOrderId(info?.id);
        setWaybill(info?.waybill);
    }, [info]);

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            onCancel={onClose}
            onOk={() => {
                setLoading(true);
                setOrderWaybill();
            }}
            title={<Space>
                <Text>{`修改所属运单（订单号：${orderId}）`}</Text>
                <Tooltip title="修改此订单所属的运单为指定序列号的运单，如要自运单中撤下订单请留空">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>}
        >
            <Input
                prefix={<FormOutlined />}
                placeholder="请输入运单序列号"
                value={waybill}
                onChange={e => setWaybill(e.target.value)}
                disabled={loading}
                allowClear={true}
            />
        </Modal>
    );
}

export const setOrderReceived = (info, recover, onSucceed) => request(
    '/superuser/set/order_received', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success(data?.received === 0 ? "取消签收成功" : "手动签收成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ refreshParent, visible, orderId, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [info, setInfo] = useState({
        basic: {
            id: orderId,
            time: 'YYYY-MM-DD HH:mm:ss',
            merchant: '王德发大酒店',
            goods: `奥利给啤酒${Math.ceil(Math.random() * 100)}箱`,
            abnormal: Math.random() > 0.8 ? 1 : 0,
            status: Math.floor(Math.random() * 100) % 4,
            waybill: '1000000007'
        },
        others: {
            item1: {
                name: '项目1',
                value: '杂项'
            },
            item2: {
                name: '项目2',
                value: '杂项'
            }
        }
    });

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        if (orderId === -1 || visible === false) {
            return;
        }
        else {
            setLoading(true);
            getOrderInfo({ id: orderId }, () => setLoading(false), setInfo, onClose);
        }
    }, [onClose, orderId, visible, updateSign]);

    const statusRender = (status) => {
        switch (status) {
            case 0: return 'unverified';
            case 1: return 'queuing';
            case 2: return 'processing';
            case 3: return 'done';
            default: return 'unknown';
        }
    };

    return (
        <>
            <Drawer
                visible={visible}
                onClose={onClose}
                width='40%'
                title={`订单详细信息（序列号：${orderId}）`}
                footer={<Content style={{ textAlign: 'right' }}>
                    <Space>
                        <Button
                            disabled={info?.basic?.status !== 0}
                            icon={<LinkOutlined />}
                            loading={loading}
                            onClick={() => setModalVisible(true)}
                        >
                            手动规划
                        </Button>
                        <Button
                            disabled={info?.basic?.status !== 3}
                            icon={<AuditOutlined />}
                            loading={loading}
                            onClick={() => {
                                setLoading(true);
                                setOrderReceived({ id: orderId }, () => setLoading(false), () => refreshParent());
                            }}
                        >
                            手动签收
                        </Button>
                        <Button
                            danger={info?.basic?.abnormal === 0}
                            type={info?.basic?.abnormal === 0 ? "default" : "primary"}
                            icon={info?.basic?.abnormal === 0 ? <WarningOutlined /> : <CheckCircleOutlined />}
                            loading={loading}
                            onClick={() => {
                                setLoading(true);
                                setOrderAbnormal({ id: orderId }, () => setLoading(false), () => {
                                    refreshParent();
                                    setUpdateSign(!updateSign);
                                });
                            }}
                        >
                            {info?.basic?.abnormal === 0 ? "设为异常" : "取消异常"}
                        </Button>
                    </Space>
                </Content>}
            >
                <Spin
                    size="large"
                    spinning={loading}
                    tip="加载中……"
                >
                    <ProDescriptions
                        title="基本信息与状态"
                        tooltip="查看订单的收货商、货品内容以及当前运送状态等信息"
                        bordered={true}
                        column={1}
                        loading={loading}
                    >
                        <ProDescriptions.Item label="订单序列号">{info?.basic?.id}</ProDescriptions.Item>
                        <ProDescriptions.Item label="录入时间">{info?.basic?.time}</ProDescriptions.Item>
                        <ProDescriptions.Item label="收货商">{info?.basic?.merchant}</ProDescriptions.Item>
                        <ProDescriptions.Item label="货品信息">{info?.basic?.goods}</ProDescriptions.Item>
                        <ProDescriptions.Item
                            label="是否异常"
                            valueEnum={{
                                no: { text: '正常', status: 'Success' },
                                yes: { text: '异常', status: 'Error' }
                            }}
                        >
                            {info?.basic?.abnormal === 0 ? 'no' : 'yes'}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item
                            label="当前阶段"
                            valueEnum={{
                                unverified : { text: '未审核', status: 'Default' },
                                queuing: { text: '待配送', status: 'Warning' },
                                processing: { text: '配送中', status: 'Processing' },
                                done: { text: '已送达', status: 'Success' },
                                unknown: { text: '未知', status: 'Error' }
                            }}
                        >
                            {statusRender(info?.basic?.status)}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item label="所属运单">
                            <Paragraph
                                copyable={info?.basic?.waybill === '' ? false : {
                                    text: info?.basic?.waybill,
                                    tooltips: ["复制运单号", "复制成功"]
                                }}
                                style={{ margin: 0 }}
                            >
                                {info?.basic?.waybill === '' ? '无所属' : info?.basic?.waybill}
                            </Paragraph>
                        </ProDescriptions.Item>
                    </ProDescriptions>
                    <Divider />
                    <ProDescriptions
                        title="详细信息与其他"
                        tooltip="查看订单的其他详细信息，该部分信息为直接从订单管理系统取得"
                        bordered={true}
                        column={1}
                        loading={loading}
                    >
                        {Object.keys(info?.others).map(key => (
                            <ProDescriptions.Item label={info?.others[key]?.name}>{info?.others[key]?.value}</ProDescriptions.Item>
                        ))}
                    </ProDescriptions>
                </Spin>
            </Drawer>
            <OrderPlannedModal
                info={{ id: orderId, waybill: info?.basic?.waybill }}
                visible={modalVisible}
                onSucceed={() => {
                    refreshParent();
                    onClose();
                }}
                onClose={() => setModalVisible(false)}
            />
        </>
    );
}