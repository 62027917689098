import React, { useEffect, useState } from 'react';
import { Space, Tag, Typography, Button, Statistic, Layout, message } from 'antd';
import { ReloadOutlined, DownloadOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import ProList from '@ant-design/pro-list';
import moment from 'moment';

import request, { herfRequest } from '../components/request';

const { Paragraph, Text } = Typography;
const { Content } = Layout;
const { CheckableTag } = Tag;

const getDataList = (info, recover, setData) => request(
    '/superuser/list/logs', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const getLogFile = () => herfRequest('/superuser/download_logs', 'smartcity.log');

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [refreshTime, setRefreshTime] = useState(moment());
    const [listData, setListData] = useState({
        statistic: {
            total: 0, // 4096
        },
        logs: [], // Array.from({ length: 3000 }, (x, i) => "WARNING: 歪比巴卜")
        total: 0 // 256
    });
    const [queryLoading, setQueryLoading] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            per_page: pageSize,
            page: pageNumber
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    }, [pageNumber, pageSize, updateSign]);

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    此页面将展示后端服务器的运行时日志。该日志由后端服务器<Text strong>自动生成</Text>，未经过可视化处理，因此如需查询日志，请咨询运维人员以了解特定日志条目的具体含义。
                </Paragraph>
                <Paragraph>
                    服务端日志文件详细记录了所有管理端、司机端用户对数据库的<Text strong>增删改查操作</Text>以及后端运行时可能出现的提示、警告和错误等信息。
                    合理利用日志文件可以有效定位服务器发生的错误，并可通过特定行为追溯到该行为的<Text strong>执行者</Text>和<Text strong>执行日期</Text>。
                </Paragraph>
            </Typography>}
            subTitle="后端服务器运行时状态记录"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            extraContent={<Statistic title="记录条数" value={listData?.statistic?.total} />}
            extra={[
                <Button
                    icon={<DownloadOutlined />}
                    loading={queryLoading}
                    onClick={() => getLogFile()}
                >
                    保存日志文件
                </Button>,
                <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => setUpdateSign(!updateSign)}
                    loading={queryLoading}
                >
                    刷新
                </Button>,
            ]}
        >
            <Content style={{ background: 'white' }}>
                <ProList
                    headerTitle="日志列表清单"
                    split={true}
                    dataSource={listData?.logs?.map(item => ({ title: item }))}
                    toolBarRender={() => (
                        <Space>
                            {["全部", "通知", "警告", "错误"].map((text, index) => (
                                <CheckableTag
                                    checked={index === 0}
                                    onChange={() => message.info("此功能尚未推出")}
                                >
                                    {text}
                                </CheckableTag>
                            ))}
                        </Space>
                    )}
                    metas={{ title: {} }}
                    pagination={{
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100],
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        size: "default",
                        total: totalNumber,
                        onChange: (page, size) => {
                            setPageNumber(page);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `第${range[0]}-${range[1]}项 共${total}项`
                    }}
                />
            </Content>
        </PageContainer>
    );
}