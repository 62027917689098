import { message } from 'antd';

export const baseURL = '/api';

export const herfRequest = (
    path = '',
    name = ''
) => {
    const link = document.createElement('a');

    link.download = name;
    link.style.display = 'none';
    link.href = `${baseURL}${path}`;
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
}

export const freeRequest = (
    path = '',
    method = 'POST',
    data = {},
    callbackSucceed = (data) => {},
    callbackFailed = (error) => {},
    recover = () => {}
) => {
    const encode = encodeURIComponent;
    const header = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: method.toUpperCase(),
        body: Object.keys(data).map(key => `${encode(key)}=${encode(data[key])}`).join('&')
    };

    fetch(`${path}`, header)
        .then(response => response.json())
        .then(data => {
            callbackSucceed(data);
            recover();
        })
        .catch(error => {
            callbackFailed(error);
            recover();
        });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (
    path = '',
    method = 'POST',
    data = {},
    callbackSucceed = (data) => {},
    callbackFailed = (error) => {},
    recover = () => {}
) => {
    const encode = encodeURIComponent;
    const header = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: method.toUpperCase(),
        credentials: 'include',
        body: Object.keys(data).map(key => `${encode(key)}=${encode(data[key])}`).join('&')
    };

    fetch(`${baseURL}${path}`, header)
        .then(response => response.json())
        .then(data => {
            if (data?.status === -1) {
                message.warning("您尚未登录或您的登陆凭证已失效，请重新登录！");
                setTimeout(() => {
                    sessionStorage.setItem('userInfo', '{"level":0,"name":""}');
                    window.location.replace('/login');
                }, 1000);
            }
            else {
                callbackSucceed(data);
            }
            recover();
        })
        .catch(error => {
            callbackFailed(error);
            recover();
        });
}