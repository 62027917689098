import React, { useEffect, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { Typography, DatePicker, Button, Space, Statistic, Layout, Tooltip, message, Tag, Input, Switch, Menu, Checkbox, InputNumber, Dropdown } from 'antd';
import { PlusOutlined, InfoCircleOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, ReloadOutlined, SearchOutlined, RollbackOutlined, DownOutlined, PlayCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDynamicList, useMount, useSetState } from 'ahooks';
import ProList from '@ant-design/pro-list';

import request from '../../components/request';
import OrderInfoDrawer from '../../components/order';

const { Paragraph, Text } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const datePair = (from, to) => ({ from: from, to: to })

const getDataList = (info, recover, setData) => request(
    '/superuser/create/orders', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const getVehiclesType = (setData) => request(
    '/superuser/create/vehicles', 'POST', {},
    (data) => {
        if (data?.status === 1) {
            setData(data?.vehicles);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    () => { }
);

const createTask = (info, recover, onSucceed) => request(
    '/superuser/create/run_algorithm', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("排线算法已开始执行，请耐心等待");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const switchSelection = (info, recover, onSucceed) => request(
    '/superuser/create/switch_select', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success('成功切换订单选中状态');
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const globalSelection = (info, recover, onSucceed) => request(
    '/superuser/create/global_select', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success('批量切换订单选中状态成功');
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const getNameByCode = (array = [], code) => {
    let result = null;
    array.forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (item?.code == code) { // Use == instead of === to deal with comparation between number and string
            result = item?.name;
        }
    });
    return result;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [preDate, setPreDate] = useState({ from: moment().subtract('1', 'days'), to: moment() });
    const [date, setDate] = useState(preDate);

    const [tabPanel, setTabPanel] = useState('2');

    const [refreshTime, setRefreshTime] = useState(moment());
    const [listData, setListData] = useState({
        statistic: {
            total: 4096,
            selected: 233
        },
        list: Array.from({ length: 3000 }, (x, i) => {
            return {
                id: 1000000000 + i,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                merchant: '王德发大酒店',
                goods: `奥利给啤酒${Math.ceil(Math.random() * 100)}箱`,
                selected: Math.ceil(Math.random() * 10) % 2 === 0 ? 1 : 0
            }
        }),
        selectedState: 1, // 0 for zero, 1 for part, 2 for all
        total: 256
    });
    const [queryLoading, setQueryLoading] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);
    const [searchText, setSearchText] = useState('');

    const [detailVisible, setDetailVisible] = useState(false);
    const [detailOrderId, setDetailOrderId] = useState(-1);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            from: date?.from?.format('YYYY-MM-DD'),
            to: date?.to?.format('YYYY-MM-DD'),
            per_page: pageSize,
            page: pageNumber,
            search: searchText
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    }, [date, pageNumber, pageSize, updateSign, searchText]);

    useMount(() => getVehiclesType(data => setVehiclesType(data)));

    const columns = [
        {
            title: '序列号',
            dataIndex: 'id',
            copyable: true,
            tooltip: '订单的唯一序列号，用于执行订单与运单的绑定时定位特定订单',
            width: '10%'
        },
        {
            title: '录入时间',
            dataIndex: 'time',
            width: '10%'
        },
        {
            title: '商户名称',
            dataIndex: 'merchant',
            ellipsis: true
        },
        {
            title: '货品信息',
            dataIndex: 'goods',
            ellipsis: true
        },
        {
            title: '管理',
            width: '10%',
            render: (_, record) => (
                <Space>
                    <Tooltip
                        title="详细信息"
                        onClick={() => {
                            setDetailOrderId(record?.id);
                            setDetailVisible(true);
                        }}
                    >
                        <Button icon={<InfoCircleOutlined />} type='text' />
                    </Tooltip>
                    <Tooltip title={`${record?.selected === 1 ? "取消" : ""}选中`}>
                        <Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            checked={record?.selected === 1}
                            onChange={() => switchSelection({ id: record?.id }, () => { }, () => setUpdateSign(!updateSign))}
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    const vehiclesList = useDynamicList([]);
    const [vehiclesType, setVehiclesType] = useState([
        { code: 0, name: '金杯' },
        { code: 1, name: '依维柯' },
        { code: 2, name: '四米二货车' }
    ]);
    const [globalParams, setGlobalParams] = useSetState({ loadCost: null, serveCost: null });
    const canWork = () => {
        let flag = true;
        if (vehiclesList.list?.length === 0) {
            flag = false;
        } else if (typeof globalParams.loadCost !== 'number' || typeof globalParams.serveCost !== 'number') {
            flag = false;
        } else {
            vehiclesList.list?.forEach(v => {
                if (v.type === -1) {
                    flag = false;
                }
            });
        }
        return flag;
    };

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    您可以在页面右上角的时间筛选器中选择需要筛选订单的日期区间，默认为查询日当天<Text strong>及之前一天</Text>。
                    此外，在调整中，您也可以点击“<Text strong>重置</Text>”按钮将时间调整回查询日当天<Text strong>及之前一天</Text>。
                    请注意所有对于查询日期区间的调整都必须点击“<Text strong>查询</Text>”（或“<Text strong>刷新</Text>”）按钮方能生效。
                </Paragraph>
                <Paragraph>
                    在页头下方的标签页中，您可以切换标签页以设置排线算法的参数或选择要纳入排线算法的订单。
                    您可以关注页头上方的蓝色“<Text strong>刷新时间</Text>”标签以判断当前数据的时效性。
                    <Text type='danger'>
                        <strong>特别注意</strong>：订单是否纳入排线算法只取决于该订单是否被选中，与页头的事件选择器<strong>无关</strong>！
                    </Text>
                </Paragraph>
            </Typography>}
            tabList={[
                {
                    tab: "参数设定",
                    key: '1',
                },
                {
                    tab: "订单选择",
                    key: '2',
                }
            ]}
            subTitle="根据设定的参数和选择订单的创建排线任务"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            tabActiveKey={tabPanel}
            onTabChange={key => setTabPanel(key)}
            extraContent={
                <Space size="large" style={{ margin: 0 }}>
                    <Statistic title="当前日期区间总订单数" value={listData?.statistic?.total} />
                    <Statistic title="当前日期区间已选订单" value={listData?.statistic?.selected} />
                </Space>
            }
            extra={[
                <Tooltip title="查询日期区间" placement="bottom">
                    <Text>
                        <RangePicker
                            allowClear={false}
                            placeholder={["起始日期", "截止日期"]}
                            value={[preDate.from, preDate.to]}
                            onChange={dates => setPreDate(datePair(dates[0], dates[1]))}
                        />
                    </Text>
                </Tooltip>,
                <Button
                    icon={<RollbackOutlined />}
                    onClick={() => setPreDate(datePair(moment(), moment()))}
                >
                    重置
                </Button>,
                <Button
                    icon={preDate === date ? <ReloadOutlined /> : <SearchOutlined />}
                    type="primary"
                    onClick={() => preDate === date ? setUpdateSign(!updateSign) : setDate(preDate)}
                    loading={queryLoading}
                >
                    {preDate === date ? '刷新' : '查询'}
                </Button>
            ]}
        >
            <Content style={{ background: 'white' }}>
                {tabPanel === '2' && (
                    <ProTable
                        search={false}
                        headerTitle="待排线订单选择"
                        options={{
                            fullScreen: true,
                            reload: false,
                            setting: true,
                            density: true
                        }}
                        toolBarRender={() => (
                            <Space size="large">
                                <Checkbox
                                    indeterminate={listData?.selectedState === 1}
                                    checked={listData?.selectedState === 2}
                                    onChange={() => globalSelection({
                                        from: date?.from?.format('YYYY-MM-DD'),
                                        to: date?.to?.format('YYYY-MM-DD'),
                                        search: searchText,
                                        cancel: listData?.selectedState < 2 ? 0 : 1
                                    }, () => { }, () => setUpdateSign(!updateSign))}
                                >
                                    全部选择
                                </Checkbox>
                                <Tooltip title="按序列号、商户或货品信息筛选订单">
                                    <Input.Search
                                        placeholder="订单模糊搜索"
                                        onSearch={value => {
                                            if (value !== searchText) {
                                                setPageNumber(1);
                                            }
                                            setSearchText(value);
                                        }}
                                        style={{ width: 300 }}
                                        allowClear={true}
                                        size="small"
                                    />
                                </Tooltip>
                            </Space>
                        )}
                        loading={queryLoading}
                        columns={columns}
                        dataSource={listData?.list}
                        pagination={{
                            position: ["bottomCenter"],
                            showQuickJumper: true,
                            showSizeChanger: true,
                            pageSizeOptions: [20, 50, 100],
                            defaultCurrent: pageNumber,
                            defaultPageSize: pageSize,
                            size: "default",
                            total: totalNumber,
                            onChange: (page, size) => {
                                setPageNumber(page);
                                setPageSize(size);
                            },
                            showTotal: (total, range) => `第${range[0]}-${range[1]}项 共${total}项`
                        }}
                    />
                )}
                {tabPanel === '1' && (
                    <Content style={{ background: 'white' }}>
                        <ProList
                            headerTitle="车型参数设定"
                            split={true}
                            dataSource={vehiclesList.list?.map((v, index) => ({
                                subTitle: (
                                    <Space>
                                        <span>
                                            车型：
                                        </span>
                                        <Dropdown
                                            overlay={(
                                                <Menu
                                                    onClick={({ key }) => vehiclesList.replace(index, { ...vehiclesList.list[index], type: key })}
                                                >
                                                    {vehiclesType?.map(v => (
                                                        <Menu.Item key={v.code}>
                                                            {v.name}
                                                        </Menu.Item>
                                                    ))}
                                                </Menu>
                                            )}
                                        >
                                            <Button size='small'>
                                                <Space>
                                                    <div style={{
                                                        width: 100,
                                                        textAlign: 'left'
                                                    }}>
                                                        {v.type === -1 ? '车型选择' : getNameByCode(vehiclesType, v.type)}
                                                    </div>
                                                    <DownOutlined />
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                        <span>
                                            &nbsp; 派车数量：
                                        </span>
                                        <InputNumber
                                            style={{ width: 200 }}
                                            value={v.number}
                                            placeholder='请输入此车型的派车数量'
                                            size='small'
                                            onChange={val => vehiclesList.replace(index, { ...vehiclesList.list[index], number: val })}
                                        />
                                        <span>
                                            &nbsp; 附加费用：
                                        </span>
                                        <InputNumber
                                            style={{ width: 200 }}
                                            value={v.additionalCost}
                                            placeholder='请输入单次派送的附加费用'
                                            size='small'
                                            onChange={val => vehiclesList.replace(index, { ...vehiclesList.list[index], additionalCost: val })}
                                        />
                                    </Space>
                                )
                            }))}
                            toolBarRender={() => (
                                <Space>
                                    <Tooltip title="货物装载时间（单位：分钟）">
                                        <InputNumber
                                            style={{ width: 300 }}
                                            placeholder='请输入预计的货物装载时间'
                                            value={globalParams.loadCost}
                                            onChange={value => setGlobalParams({ loadCost: value })}
                                        />
                                    </Tooltip>
                                    <Tooltip title="平均服务时间（单位：分钟）">
                                        <InputNumber
                                            style={{ width: 300 }}
                                            placeholder='请输入单个订单目的地的预计服务时间'
                                            value={globalParams.serveCost}
                                            onChange={value => setGlobalParams({ serveCost: value })}
                                        />
                                    </Tooltip>
                                    <Button
                                        type='dashed'
                                        onClick={() => vehiclesList.push({
                                            type: -1,
                                            number: 1,
                                            additionalCost: 0
                                        })}
                                    >
                                        <PlusOutlined />
                                        添加新车型
                                    </Button>
                                    <Button
                                        type='primary'
                                        onClick={() => {
                                            setQueryLoading(true);
                                            createTask({
                                                vehicles: vehiclesList.list,
                                                ...globalParams
                                            }, () => { }, () => setUpdateSign(!updateSign));
                                        }}
                                        disabled={!canWork()}
                                    >
                                        <PlayCircleOutlined />
                                        开始排线
                                    </Button>
                                </Space>
                            )}
                            metas={{
                                title: {},
                                subTitle: {},
                                extra: {
                                    render: (_, __, index) => (
                                        <Tooltip title="删除此车型">
                                            <Button
                                                onClick={() => vehiclesList.remove(index)}
                                                icon={<DeleteOutlined />}
                                                shape='circle'
                                                danger={true}
                                            />
                                        </Tooltip>
                                    )
                                }
                            }}
                        />
                    </Content>
                )}
            </Content>
            <OrderInfoDrawer
                visible={detailVisible}
                orderId={detailOrderId}
                refreshParent={() => setUpdateSign(!updateSign)}
                onClose={() => setDetailVisible(false)}
            />
        </PageContainer>
    );
}