import React, { useEffect, useState } from 'react';
import { List, Modal, message, Space, Tooltip, Input, Spin, Layout, Image, Empty, DatePicker, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import request from './request';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Text, Paragraph } = Typography;

export const acceptDriver = (info, recover, onSucceed) => request(
    '/superuser/verify_driver', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("通过审核成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

export const deleteDriver = (info, recover, onSucceed) => request(
    '/superuser/delete_driver', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("删除司机成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

const modifyOrCreateDriver = (info, recover, onSucceed) => request(
    '/superuser/update_driver', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("新建/修改司机信息成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

export const DriverImagesModal = ({ driverId, visible, onClose }) => {
    const [loading, setLoading] = useState(true);
    const [imageList, setImageList] = useState([]);

    const getDriverImages = (info, recover, setData, onFailed) => request(
        '/superuser/driver_get_image', 'POST', info,
        (data) => {
            if (data?.status === 1) {
                setData(data);
            }
            else {
                message.warning(data?.info);
                onFailed();
            }
        },
        (error) => {
            message.error("网络错误");
            onFailed();
        },
        recover
    );

    useEffect(() => {
        if (driverId === -1 || visible === false) {
            return;
        }
        else {
            setLoading(true);
            getDriverImages({ driverId: driverId }, () => setLoading(false), data => setImageList(data?.images), onClose);
        }
    }, [driverId, visible, onClose]);

    return (
        <Modal
            title={`司机身份认证信息（司机编号：${driverId}）`}
            visible={visible}
            onCancel={onClose}
            onOk={onClose}
        >
            <Content style={{ textAlign: 'center' }}>
                <Spin
                    size="large"
                    tip="图片加载中"
                    spinning={loading}
                >
                    {loading ? 
                        <Content style={{ minHeight: 140 }} />
                    : <Image.PreviewGroup>
                        {imageList?.length > 0 ?
                            imageList.map(item => <Image src={`data:image/png;base64,${item}`} />)
                        : <Empty />}
                    </Image.PreviewGroup>}
                </Spin>
            </Content>
        </Modal>
    );
}

export const QueryBillModal = ({ visible, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [driverId, setDriverId] = useState('');
    const [fromTime, setFromTime] = useState(moment());
    const [dueTime, setDueTime] = useState(moment());
    const [listData, setListData] = useState({
        total: 0,
        totalBill: 0,
        list: []
    });

    const getBillInfo = (recover) => request(
        '/superuser/get_driver_bill', 'POST', {
            driverId: driverId,
            from: fromTime.format('YYYY-MM-DD'),
            to: dueTime.format('YYYY-MM-DD')
        },
        (data) => {
            if (data?.status === 1) {
                setListData(data);
            }
            else {
                message.warning(data?.info);
            }
        },
        (error) => message.error("网络错误"),
        recover
    );

    return (
        <Modal
            title="司机运输总值查询"
            visible={visible}
            onCancel={onClose}
            cancelText="关闭"
            onOk={() => {
                if (driverId === '') {
                    message.info("请输入司机编号！");
                    return;
                }
                setLoading(true);
                getBillInfo(() => setLoading(false));
            }}
            okText="查询"
            confirmLoading={loading}
            width={600}
        >
            <List
                bordered={false}
                size="small"
                split={false}
            >
                <List.Item
                    extra={<Input
                        value={driverId}
                        onChange={e => setDriverId(e.target.value)}
                        placeholder="请输入司机编号"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    司机编号
                </List.Item>
                <List.Item
                    extra={<RangePicker
                        allowClear={false}
                        placeholder={["起始日期", "截止日期"]}
                        value={[fromTime, dueTime]}
                        onChange={dates => {
                            setFromTime(dates[0]);
                            setDueTime(dates[1])
                        }}
                        style={{ width: '70%' }}
                    />}
                >
                    查询日期区间
                </List.Item>
            </List>
            <Spin
                spinning={loading}
                size="large"
                tip="查询中，请稍候"
            >
                <List
                    bordered={false}
                    size="small"
                    header={<>
                        <Text strong>查询结果</Text>
                        <Text style={{ float: 'right' }}>
                            共<Text strong>{listData?.total}</Text>项, 合计价值￥<Text strong>{listData?.totalBill}</Text>（CNY）
                        </Text>
                    </>}
                    pagination={{
                        position: 'bottom'
                    }}
                >
                    {listData?.list?.length > 0 ? listData?.list?.map(value => (
                        <List.Item
                            extra={<Text>
                                <Text strong>{value?.bill}</Text>
                                {" CNY（￥）"}
                            </Text>}
                        >
                            <Paragraph
                                copyable={{
                                    tooltips: ["复制运单号", "复制成功"],
                                    text: value?.waybillId
                                }}
                                style={{ margin: 0 }}
                            >
                                订单号：<Text strong>{value?.waybillId}</Text>
                            </Paragraph>

                        </List.Item>
                    )) : <Empty style={{ marginTop: 16 }} />}
                </List>
            </Spin>
        </Modal>
    );
}

export const setDriverAbnormal = (info, recover, onSucceed) => request(
    '/superuser/set_driver_abnormal', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success(data?.abnormal === 0 ? "冻结司机成功" : "取消冻结成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ refreshParent, info = {
    id: '',
    name: '',
    idCardNumber: '',
    phoneNumber: '',
    driverCardNumber: '',
    remarks: ''
}, visible, onClose }) => {
    const [driverId, setDriverId] = useState(info?.id);
    const [name, setName] = useState(info?.name);
    const [idCardNumber, setIdCardNumber] = useState(info?.idCardNumber);
    const [phoneNumber, setPhoneNumber] = useState(info?.phoneNumber);
    const [driverCardNumber, setDriverCardNumber] = useState(info?.driverCardNumber);
    const [remarks, setRemarks] = useState(info?.remarks);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDriverId(info?.id);
        setName(info?.name);
        setIdCardNumber(info?.idCardNumber);
        setPhoneNumber(info?.phoneNumber);
        setDriverCardNumber(info?.driverCardNumber);
        setRemarks(info?.remarks);
    }, [info]);

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            onCancel={onClose}
            onOk={() => {
                let flag = true;
                [name, phoneNumber, idCardNumber, driverCardNumber].forEach(value => {
                    if (['', null, undefined].includes(value)) {
                        flag = false;
                    }
                });
                if (!flag) {
                    message.info("请确保除备注外的所有项目都已填写！");
                    return;
                }
                setLoading(true);
                modifyOrCreateDriver(
                    {
                        driverId: driverId,
                        name: name,
                        idCardNumber: idCardNumber,
                        phoneNumber: phoneNumber,
                        driverCardNumber: driverCardNumber,
                        remarks: remarks
                    },
                    () => setLoading(false),
                    () => {
                        refreshParent();
                        onClose();
                    }
                );
            }}
            title={<Space>
                {driverId !== -1 ? `修改司机信息（司机编号：${driverId}）` : "新建司机"}
                <Tooltip title="除备注外，所有项目均需填写">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>}
            width={600}
        >
            <List
                bordered={false}
                size="small"
                split={false}
            >
                <List.Item
                    extra={<Input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder="请输入司机姓名"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    司机姓名
                </List.Item>
                <List.Item
                    extra={<Input
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        placeholder="请输入司机手机号"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    手机号
                </List.Item>
                <List.Item
                    extra={<Input
                        value={idCardNumber}
                        onChange={e => setIdCardNumber(e.target.value)}
                        placeholder="请输入司机身份证号"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    身份证号
                </List.Item>
                <List.Item
                    extra={<Input
                        value={driverCardNumber}
                        onChange={e => setDriverCardNumber(e.target.value)}
                        placeholder="请输入司机驾驶证号"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    驾驶证号
                </List.Item>
                <List.Item
                    extra={<Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        maxLength={256}
                        showCount={true}
                        value={remarks}
                        onChange={e => setRemarks(e.target.value)}
                        placeholder="请输入针对该司机的备注（可以留空）"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    备注
                </List.Item>
            </List>
        </Modal>
    );
}