import React, { useEffect, useState } from 'react';
import { List, Modal, message, Space, Select, Typography, Tooltip, Input, InputNumber, Table, Spin, Empty, Divider } from 'antd';
import { InfoCircleOutlined, FormOutlined } from '@ant-design/icons';
import ProDescriptions from '@ant-design/pro-descriptions';

import request from './request';

const { Text, Title } = Typography;

const provinces = ['粤', '川', '云', '贵', '陕', '甘', '琼', '鄂', '湘', '赣', '闽', '浙', '皖', '苏', '豫', '鲁', '青', '冀', '晋', '辽', '吉', '黑', '桂', '宁', '藏', '新', '蒙', '渝', '沪', '津', '京'];

const getProvinceIndex = (value) => {
    let result = '';
    provinces.forEach((item, index) => {
        if (value === item) {
            result = index;
        }
    });
    return result === '' ? 0 : result;
}

const ValueInput = ({ value = '', setValue = (value) => { }, disabled = false }) => (
    <Input
        style={{
            width: 40,
            textAlign: 'center'
        }}
        maxLength={1}
        disabled={disabled}
        value={value}
        onChange={e => /^[a-zA-Z0-9]$/.test(e.target.value) ? setValue(e.target.value.toUpperCase()) : setValue('')}
    />
);

export const acceptWaybill = (info, recover, onSucceed) => request(
    '/waybill/verify_waybill', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("通过审核成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

export const deleteWaybill = (info, recover, onSucceed) => request(
    '/waybill/delete_waybill', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("删除运单成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

export const ModifyCommentModal = ({
    info = { id: '', comments: '' },
    visible,
    onSucceed = () => { },
    onClose = () => { }
}) => {
    const [loading, setLoading] = useState(false);

    const [waybillId, setWaybillId] = useState(info?.id);
    const [comments, setComment] = useState(info?.comments);

    const setWaybillComment = () => request(
        '/waybill/comments_on_waybill', 'POST', {
        waybillId: waybillId, comments: comments
    }, (data) => {
        message.success("改写注释成功");
        onClose();
        onSucceed();
    },
        (error) => {
            message.error("网络错误");
            setLoading(false);
        },
        () => setLoading(false)
    );

    useEffect(() => {
        setWaybillId(info?.id);
        setComment(info?.comments);
    }, [info]);

    return (
        <Modal
            width={600}
            visible={visible}
            confirmLoading={loading}
            onCancel={onClose}
            onOk={() => {
                setLoading(true);
                setWaybillComment();
            }}
            title={<Space>
                <Text>{`修改运单注释（运单号：${waybillId}）`}</Text>
                <Tooltip title="修改此运单的附注文本，此文本的相关修改会被推送至承运司机手机端">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>}
        >
            <Input.TextArea
                autoSize={{ minRows: 7, maxRows: 10 }}
                maxLength={512}
                showCount={true}
                placeholder="请输入注释文本，如想删除注释则请留空"
                value={comments}
                onChange={e => setComment(e.target.value)}
                disabled={loading}
                allowClear={true}
            />
        </Modal>
    );
}

export const ModifyBillModal = ({
    info = { id: '', bill: 0 },
    visible,
    onSucceed = () => { },
    onClose = () => { }
}) => {
    const [loading, setLoading] = useState(false);

    const [waybillId, setWaybillId] = useState(info?.id);
    const [bill, setBill] = useState(info?.bill);

    const setWaybillBill = () => request(
        '/waybill/bill_on_waybill', 'POST', {
        waybillId: waybillId, bill: bill
    }, (data) => {
        message.success("修改运单价值成功");
        onClose();
        onSucceed();
    },
        (error) => {
            message.error("网络错误");
            setLoading(false);
        },
        () => setLoading(false)
    );

    useEffect(() => {
        setWaybillId(info?.id);
        setBill(info?.bill);
    }, [info]);

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            onCancel={onClose}
            onOk={() => {
                setLoading(true);
                setWaybillBill();
            }}
            title={<Space>
                <Text>{`修改运单价值（运单号：${waybillId}）`}</Text>
                <Tooltip title="修改此运单的运单价值，该数值的修改将影响承运司机的收入结算">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>}
        >
            <InputNumber
                style={{ width: '100%' }}
                prefix={<FormOutlined />}
                placeholder="请输入运单价值"
                value={bill}
                onChange={value => setBill(value)}
                disabled={loading}
                allowClear={true}
            />
        </Modal>
    );
}

export const SummaryModal = ({
    id = '',
    visible,
    onClose = () => { }
}) => {
    const [loading, setLoading] = useState(true);

    const [waybillId, setWaybillId] = useState(id);
    const [generalInfo, setGeneralInfo] = useState({
        columns: [],
        data: []
    });
    const [vehicleInfo, setVehicleInfo] = useState([]);

    const getSummaryInfo = () => request(
        '/waybill/get_summary', 'POST', {
        waybillId: waybillId
    }, (data) => {
        setGeneralInfo(data?.general);
        setVehicleInfo(data?.vehicle);
    },
        (error) => {
            message.error("网络错误");
            onClose();
        },
        () => setLoading(false)
    );

    useEffect(() => {
        setLoading(true);
        setWaybillId(id);
        getSummaryInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            onOk={onClose}
            title={<Space>
                <Text>{`排线信息（运单${waybillId}所属当次排线）`}</Text>
                <Tooltip title="展示该运单所属当次排线的全部汇总信息，包含车型与费用统计等数据">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>}
            width={1400}
        >
            <Spin
                spinning={loading}
                size="large"
                tip="加载数据中……"
            >
                <Title level={4}>
                    数据总览
                </Title>
                <Table
                    columns={generalInfo.columns}
                    dataSource={generalInfo.data}
                    pagination={false}
                    size='small'
                />
                <Divider />
                <Title level={4}>
                    分车型数据统计
                </Title>
                {vehicleInfo.length > 0 ? (
                    <div style={{ width: '100%', display: 'flex' }}>
                        {vehicleInfo.map((val, ind) => (
                            <div style={{ width: '25%', padding: '5px' }}>
                                <ProDescriptions
                                    key={ind}
                                    bordered={true}
                                    column={1}
                                    size='small'
                                    style={{ width: 'auto' }}
                                >
                                    {val.map((obj, innerInd) => (
                                        <ProDescriptions.Item
                                            key={innerInd}
                                            label={obj.name}
                                        >
                                            {obj.value}
                                        </ProDescriptions.Item>
                                    ))}
                                </ProDescriptions>
                            </div>
                        ))}
                    </div>
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </Modal>
    );
}

const modifyOrCreateWaybill = (info, recover, onSucceed) => request(
    '/waybill/update_waybill', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("新建/修改运单信息成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ refreshParent, info = {
    waybillId: '',
    driverId: '',
    vehicleId: '',
    carNumber: []
}, visible, onClose }) => {
    const [waybillId, setWaybillId] = useState(info?.waybillId);
    const [driverId, setDriverId] = useState(info?.driverId);
    const [vehicleId, setVehicleId] = useState(info?.vehicleId);
    const [carNumber, setCarNumber] = useState(info?.carNumber?.concat(Array.from({ length: 8 - info?.carNumber?.length }, (x, i) => '')));

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setWaybillId(info?.waybillId);
        setDriverId(info?.driverId);
        setVehicleId(info?.vehicleId);
        setCarNumber(info?.carNumber?.concat(Array.from({ length: 8 - info?.carNumber?.length }, (x, i) => '')));
    }, [info]);

    return (
        <Modal
            visible={visible}
            confirmLoading={loading}
            onCancel={onClose}
            onOk={() => {
                let carNumberString = '';
                carNumber.forEach(value => carNumberString += value);
                if (carNumberString.length > 1 && carNumberString.length < 7) {
                    message.warning("请填写正确完整的车辆牌照信息");
                }
                else {
                    if (carNumberString.length <= 1) {
                        carNumberString = '';
                    }
                    setLoading(true);
                    modifyOrCreateWaybill(
                        {
                            waybillId: waybillId,
                            driverId: driverId,
                            vehicleId: vehicleId,
                            carNumber: carNumberString
                        },
                        () => setLoading(false),
                        () => {
                            refreshParent();
                            onClose();
                        }
                    );
                }
            }}
            title={<Space>
                {waybillId?.length > 0 ? `修改运单信息（运单号：${waybillId}）` : "新建运单"}
                <Tooltip title="所有项目均可留空">
                    <InfoCircleOutlined />
                </Tooltip>
            </Space>}
            width={600}
        >
            <List
                bordered={false}
                size="small"
                split={false}
            >
                <List.Item
                    extra={<Input
                        value={driverId}
                        onChange={e => setDriverId(e.target.value)}
                        placeholder="请输入司机编号（可进入司机管理页面查询）"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    司机编号
                </List.Item>
                <List.Item
                    extra={<Input
                        value={vehicleId}
                        onChange={e => setVehicleId(e.target.value)}
                        placeholder="请输入车型编号（可进入车型管理页面查询）"
                        style={{ width: '70%' }}
                        allowClear={true}
                    />}
                >
                    车型编号
                </List.Item>
                <List.Item
                    extra={<Input.Group
                        style={{
                            textAlign: 'right',
                            width: '70%'
                        }}
                        compact={true}
                    >
                        <Select
                            labelInValue={true}
                            value={{ value: getProvinceIndex(carNumber[0]), label: carNumber[0] }}
                            onChange={({ value }) => setCarNumber(carNumber.map((x, i) => i === 0 ? provinces[Number(value)] : x))}
                            options={provinces.map((item, index) => ({ value: String(index), label: item }))}
                        />
                        <ValueInput
                            value={carNumber[1]}
                            setValue={value => setCarNumber(carNumber.map((x, i) => i === 1 ? value : x))}
                        />
                        <Input
                            style={{
                                width: 30,
                                pointerEvents: 'none',
                                textAlign: 'center'
                            }}
                            placeholder="-"
                            disabled={true}
                        />
                        {[2, 3, 4, 5, 6, 7].map(index => (
                            <ValueInput
                                value={carNumber[index]}
                                setValue={value => setCarNumber(carNumber.map((x, i) => i === index ? value : x))}
                            />
                        ))}
                    </Input.Group>}
                >
                    车辆牌照
                </List.Item>
            </List>
        </Modal>
    );
}