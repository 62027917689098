import React, { useEffect, useState } from 'react';
import { useSessionStorageState } from 'ahooks';
import ReactDOM from 'react-dom';
import './less/index.less';
import { BrowserRouter, useLocation, useHistory, Route, Redirect, Switch } from 'react-router-dom';

import { Typography, Avatar, Space, Tag, Tooltip, Button, Popconfirm, message } from 'antd';
import { GlobalOutlined, UserOutlined, FileSearchOutlined, BlockOutlined, AreaChartOutlined, CarOutlined, DeploymentUnitOutlined, LogoutOutlined } from '@ant-design/icons';
import ProLayout from '@ant-design/pro-layout';
import Footer from './components/footer';

import logoImg from './assets/icons/Logo.png';

import LoginPage from './pages/login';
import HomePage from './pages/home';
import OrdersPage from './pages/waybill/orders';
import WaybillPage from './pages/waybill/management';
import CreatePage from './pages/waybill/create';
import MapPage from './pages/map';
import DriversPage from './pages/logistic/drivers';
import VehiclesPage from './pages/logistic/vehicles';
import LogPage from './pages/log';

import request, { baseURL } from './components/request';

const { Text } = Typography;

const listedRoutes = (target) => {
    const queue = [], result = [];

    queue.push(target.routes);
    while (queue.length > 0) {
        let route = queue.shift();
        if (route === undefined) {
            continue;
        }
        route.forEach((subRoute) => {
            if (subRoute.routes === undefined || subRoute.routes.length === 0) {
                result.push(subRoute.path);
            }
            else {
                queue.push(subRoute.routes);
            }
        });
    }

    return result;
}

const getRouteComponent = (target, pathname) => { // The root route will not be catched.
    const queue = [];

    queue.push(target.routes);
    while (queue.length > 0) {
        let route = queue.shift(), result = null;
        if (route === undefined) {
            continue;
        }
        route.forEach((subRoute) => {
            if (subRoute.path === pathname) {
                result = subRoute.component;
            }
            queue.push(subRoute.routes);
        });
        if ([null, undefined].includes(result) === false) {
            return result;
        }
    }

    return null;
}

const router = {
    path: '/',
    routes: [
        {
            path: '/home',
            name: '总览',
            icon: <AreaChartOutlined />,
            component: <HomePage />
        },
        {
            path: '/freight',
            name: '运单',
            icon: <CarOutlined />,
            routes: [
                {
                    path: '/freight/orders',
                    name: '订单列表',
                    component: <OrdersPage />
                },
                {
                    path: '/freight/management',
                    name: '运单管理',
                    component: <WaybillPage />
                },
                {
                    path: '/freight/create',
                    name: '新建排线任务',
                    component: <CreatePage />
                }
            ],
        },
        {
            name: '物流',
            icon: <DeploymentUnitOutlined />,
            path: '/logistics',
            routes: [
                {
                    path: '/logistics/drivers',
                    name: '司机管理',
                    component: <DriversPage />
                },
                {
                    path: '/logistics/vehicles',
                    name: '车型管理',
                    component: <VehiclesPage />
                }
            ],
        },
        {
            path: '/map',
            name: '地图',
            icon: <GlobalOutlined />,
            component: <MapPage />
        },
        {
            path: '/log',
            name: '日志',
            icon: <BlockOutlined />,
            component: <LogPage />
        },
    ]
};

const setting = {
    navTheme: "light",
    layout: "side",
    contentWidth: "Fluid",
    fixedHeader: true,
    fixSiderbar: true,
    title: "SUSTech TMS",
    pwa: false,
    menu: {
        locale: true
    },
    headerHeight: 48,
    siderWidth: 220,
    logo: logoImg
};

const userLogout = (history, recover, setData) => {
    request('/superuser/logout', 'POST', {},
        (data) => {
            message.success("注销成功，将跳转至登录页面");
            setData({ level: 0, name: '' });
            setTimeout(() => history.push('/login'), 1000);
        },
        (error) => message.error("网络错误"),
        recover
    );
}

const BasePage = () => {
    const [location, history] = [useLocation(), useHistory()];
    const [userInfo, setUserInfo] = useSessionStorageState("userInfo", { defaultValue: { level: 0, name: '' } });
    const [logoutLoading, setLogoutLoading] = useState(false);

    const userInfoRender = () => {
        let [color, text] = ["red", "禁止查看"];
        switch (userInfo.level) {
            case 0: default: color = "red"; text = "验证非法"; break;
            case 1: color = "orange"; text = "只读权限"; break;
            case 2: color = "green"; text = "部分权限"; break;
            case 3: color = "purple"; text = "完全权限"; break;
        }

        return (
            <Space size="middle">
                <Tag color={color} style={{ margin: 0 }}>{text}</Tag>
                <Text strong>{`${userInfo.level > 0 ? userInfo.name : "未登录"}`}</Text>
                <Avatar shape="square" size="small" icon={<UserOutlined />} />
                {userInfo.level > 0 && <Tooltip placement="bottomRight" title="注销">
                    <Popconfirm
                        placement="bottomRight"
                        title={<Space direction="vertical">
                            <Text strong>确认注销？</Text>
                            <Text>注销后，您的登录凭证将从服务器删除；<br />若要再次进入系统操作则需要重新登录。</Text>
                        </Space>}
                        onConfirm={() => {
                            setLogoutLoading(true);
                            userLogout(history, () => setLogoutLoading(false), setUserInfo);
                        }}
                        okText="确认注销"
                        cancelText="取消"
                        okButtonProps={{ loading: logoutLoading }}
                    >
                        <Button type="text">
                            <LogoutOutlined />
                        </Button>
                    </Popconfirm>
                </Tooltip>}
            </Space>
        );
    };

    useEffect(() => {
        if (listedRoutes(router).includes(location.pathname) === false) {
            history.push('/');
        }
        else if (userInfo.level === 0) {
            // message.warning("您尚未登录，请登录后再进行操作！"); // TODO: Can't use due to bounce, meybe 'useDebounce' can solve this problem.
            history.push('/login');
        }
    }, [location, history, userInfo]);

    return (
        <ProLayout
            {...setting}
            style={{ minHeight: '100vh' }}
            route={router}
            location={location}
            waterMarkProps={{
                content: 'SUSTech - ' + userInfo?.name,
            }}
            menuFooterRender={(props) => {
                return (
                    <a
                        style={{
                            lineHeight: '48rpx',
                            display: 'flex',
                            height: 48,
                            color: 'black',
                            alignItems: 'center',
                        }}
                        href={`${baseURL}/admin`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FileSearchOutlined style={{ margin: 16 }} />
                        {!props?.collapsed && '相关文档'}
                    </a>
                );
            }}
            onMenuHeaderClick={() => history.push('/')}
            menuItemRender={(item, dom) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a onClick={() => history.push(item.path)}>
                    {dom}
                </a>
            )}
            rightContentRender={userInfoRender}
            footerRender={() => <Footer />}
        >
            {getRouteComponent(router, location.pathname)}
        </ProLayout>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Redirect exact from='/' to='/freight/management' />
                <Route exact path='/login' component={LoginPage} />
                <Route component={BasePage} />
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);