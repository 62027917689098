import React, { useEffect, useState } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { Typography, DatePicker, Button, Space, Statistic, Layout, Tooltip, message, Tag, Input, Switch, Popconfirm } from 'antd';
import { SendOutlined, CheckCircleOutlined, WarningOutlined, InfoCircleOutlined, LinkOutlined, DisconnectOutlined, AuditOutlined, ExceptionOutlined, CheckOutlined, CloseOutlined, AppstoreOutlined, AppstoreAddOutlined, ReloadOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import moment from 'moment';

import request from '../../components/request';
import OrderInfoDrawer, { refreshOrderList, setOrderAbnormal, setOrderReceived, OrderPlannedModal } from '../../components/order';

const { Paragraph, Text } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { CheckableTag } = Tag;

const datePair = (from, to) => ({ from: from, to: to })

const getDataList = (info, recover, setData) => request(
    '/superuser/list/orders', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [preDate, setPreDate] = useState({ from: moment(), to: moment() });
    const [date, setDate] = useState(preDate);

    const [tabPanel, setTabPanel] = useState('2'); // Also stands for status.
    const [subTabPanel, setSubTabPanel] = useState(0);

    const [refreshTime, setRefreshTime] = useState(moment());
    const [listData, setListData] = useState({
        statistic: {
            total: 0, // 4096
            unverified: 0, // 2048
            queuing: 0, // 1024
            processing: 0, // 512
            done: 0, // 510
            abnormal: 0 // 2
        },
        list: [] /* Array.from({ length: 3000 }, (x, i) => {
            return {
                id: 1000000000 + i,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                merchant: '王德发大酒店',
                goods: `奥利给啤酒${Math.ceil(Math.random() * 100)}箱`,
                abnormal: Math.random() > 0.8 ? 1 : 0,
                status: Math.floor(Math.random() * 100) % 4,
                planned: Math.random() > 0.4 ? 1 : 0,
                pickup: Math.random() > 0.4 ? 1 : 0,
                received: Math.random() > 0.4 ? 1 : 0
            }
        }) */,
        total: 0 // 256
    });
    const [queryLoading, setQueryLoading] = useState(false);
    const [twoStepVerify, setTwoStepVerify] = useState(true);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);

    const [detailVisible, setDetailVisible] = useState(false);
    const [detailOrderId, setDetailOrderId] = useState(-1);
    const [modalVisible, setModalVisible] = useState(false);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            from: date?.from?.format('YYYY-MM-DD'),
            to: date?.to?.format('YYYY-MM-DD'),
            per_page: pageSize,
            page: pageNumber,
            status: Number(tabPanel),
            constraint: subTabPanel // 0 for all, 1 for abnormal only, 2 for specific constraint per status.
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    }, [date, pageNumber, pageSize, tabPanel, subTabPanel, updateSign]);

    const tabPanelsTitle = ['', '未审核', '待配送', '配送中', '已送达'];
    const columns = [
        {
            title: '序列号',
            dataIndex: 'id',
            copyable: true,
            tooltip: '订单的唯一序列号，用于执行订单与运单的绑定时定位特定订单',
            width: '10%'
        },
        {
            title: '录入时间',
            dataIndex: 'time',
            width: '10%'
        },
        {
            title: '商户名称',
            dataIndex: 'merchant',
            ellipsis: true
        },
        {
            title: '货品信息',
            dataIndex: 'goods',
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'abnormal',
            render: (_, record) => (
                <>
                    <Tag color={_ === 0 ? 'green' : 'red'}>
                        <Space size="small">
                            {_ === 0 ? <CheckCircleOutlined /> : <WarningOutlined />}
                            {_ === 0 ? '正常' : '异常'}
                        </Space>
                    </Tag>
                    {/* Special status for unverified */}
                    {record?.status === 0 && <Tag color={record?.planned === 1 ? "blue" : "orange"}>
                        <Space size="small">
                            {record?.planned === 1 ? <LinkOutlined /> : <DisconnectOutlined />}
                            {record?.planned === 1 ? "已规划" : "未规划"}
                        </Space>
                    </Tag>}
                    {record?.status === 1 && <Tag color={record?.pickup === 1 ? "blue" : "orange"}>
                        <Space size="small">
                            {record?.pickup === 1 ? <AppstoreOutlined /> : <AppstoreAddOutlined />}
                            {record?.pickup === 1 ? "揽货中" : "未揽货"}
                        </Space>
                    </Tag>}
                    {record?.status === 3 && <Tag color={record?.received === 1 ? "blue" : "orange"}>
                        <Space size="small">
                            {record?.received === 1 ? <AuditOutlined /> : <ExceptionOutlined />}
                            {record?.received === 1 ? "已签收" : "未签收"}
                        </Space>
                    </Tag>}
                </>
            ),
            width: '10%'
        },
        {
            title: '管理',
            width: '8%',
            render: (_, record) => (
                <Space>
                    <Tooltip
                        title="详细信息"
                        onClick={() => {
                            setDetailOrderId(record?.id);
                            setDetailVisible(true);
                        }}
                    >
                        <Button icon={<InfoCircleOutlined />} />
                    </Tooltip>
                    {record?.status === 0 && <Tooltip title="手动规划">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify || record?.abnormal !== 0}
                            title={<Space direction="vertical">
                                <Text strong>确认进行手动规划？</Text>
                                <Text>若如此做，您的订单和运单可能将有违约束条件；<br />在此情况下请手动在对应运单中添加注释以进行说明。</Text>
                            </Space>}
                            onConfirm={() => {
                                setDetailOrderId(record?.id);
                                setModalVisible(true);
                            }}
                            okText="开始规划"
                            cancelText="取消"
                        >
                            <Button
                                disabled={record?.abnormal !== 0}
                                icon={<LinkOutlined />}
                                onClick={() => {
                                    if (twoStepVerify) {
                                        return;
                                    }
                                    else {
                                        setDetailOrderId(record?.id);
                                        setModalVisible(true);
                                    }
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>}
                    {record?.status === 3 && <Tooltip title={record?.received === 1 ? "取消签收" : "手动签收"}>
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify || record?.abnormal !== 0}
                            title={<Space direction="vertical">
                                <Text strong>确认进行手动签收/取消签收？</Text>
                                <Text>若如此做，您的订单状态将可能与收货方相异；<br />请确保您的这一操作不会对订单的正常结算产生影响。</Text>
                            </Space>}
                            onConfirm={() => setOrderReceived({ id: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText={record?.received === 1 ? "取消签收" : "手动签收"}
                            cancelText="取消"
                        >
                            <Button
                                disabled={record?.abnormal !== 0}
                                icon={record?.received === 1 ? <AuditOutlined /> : <ExceptionOutlined />}
                                onClick={() => !twoStepVerify && setOrderReceived({ id: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            />
                        </Popconfirm>
                    </Tooltip>}
                    <Tooltip title={record?.abnormal === 0 ? "设为异常" : "取消异常"}>
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认将此订单设为异常/取消异常？</Text>
                                <Text>异常订单在解除异常之前将不能进入下一阶段；<br />请确保您的这一操作不会对订单的正常结算产生影响。</Text>
                            </Space>}
                            onConfirm={() => setOrderAbnormal({ id: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText={record?.abnormal === 0 ? "设为异常" : "取消异常"}
                            cancelText="取消"
                            okButtonProps={{ danger: record?.abnormal === 0 }}
                        >
                            <Button
                                icon={record?.abnormal === 0 ? <WarningOutlined /> : <CheckCircleOutlined />}
                                danger={record?.abnormal === 0}
                                type={record?.abnormal === 0 ? "default" : "primary"}
                                onClick={() => !twoStepVerify && setOrderAbnormal({ id: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    您可以在页面右上角的时间筛选器中选择需要查询的日期区间，默认为查询日当天。
                    此外，在调整中，您也可以点击“<Text strong>重置</Text>”按钮将时间调整回查询日当天。
                    请注意所有对于查询日期区间的调整都必须点击“<Text strong>查询</Text>”（或“<Text strong>刷新</Text>”）按钮方能生效。
                </Paragraph>
                <Paragraph>
                    在页头下方的标签页中，您可以切换标签页以查看不同状态的订单列表。
                    每次切换标签页都会按照您设定的查询日期区间向服务器后台进行查询。
                    您可以关注页头上方的蓝色“<Text strong>刷新时间</Text>”标签以判断当前数据的时效性。
                </Paragraph>
            </Typography>}
            tabList={[
                {
                    tab: tabPanelsTitle[1],
                    key: '1',
                },
                {
                    tab: tabPanelsTitle[2],
                    key: '2',
                },
                {
                    tab: tabPanelsTitle[3],
                    key: '3',
                },
                {
                    tab: tabPanelsTitle[4],
                    key: '4',
                }
            ]}
            subTitle="指定时间区间内所有已处理的订单状态"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            tabActiveKey={tabPanel}
            onTabChange={key => {
                setTabPanel(key);
                setSubTabPanel(0);
            }}
            extraContent={
                <Space size="large" style={{ margin: 0 }}>
                    <Statistic title="总订单数" value={listData?.statistic?.total} />
                    <Statistic title={tabPanelsTitle[1]} value={listData?.statistic?.unverified} />
                    <Statistic title={tabPanelsTitle[2]} value={listData?.statistic?.queuing} />
                    <Statistic title={tabPanelsTitle[3]} value={listData?.statistic?.processing} />
                    <Statistic title={tabPanelsTitle[4]} value={listData?.statistic?.done} />
                    <Statistic title="异常订单" value={listData?.statistic?.abnormal} />
                </Space>
            }
            extra={[
                <Tooltip title="查询日期区间" placement="bottom">
                    <Text> {/* Using for tooltip */}
                        <RangePicker
                            allowClear={false}
                            placeholder={["起始日期", "截止日期"]}
                            value={[preDate.from, preDate.to]}
                            onChange={dates => setPreDate(datePair(dates[0], dates[1]))}
                        />
                    </Text>
                </Tooltip>,
                <Button
                    icon={<RollbackOutlined />}
                    onClick={() => setPreDate(datePair(moment(), moment()))}
                >
                    重置
                </Button>,
                <Button
                    icon={preDate === date ? <ReloadOutlined /> : <SearchOutlined />}
                    type="primary"
                    onClick={() => preDate === date ? setUpdateSign(!updateSign) : setDate(preDate)}
                    loading={queryLoading}
                >
                    {preDate === date ? '刷新' : '查询'}
                </Button>,
                <Popconfirm
                    placement="bottomRight"
                    title={<Space direction="vertical">
                        <Text strong>确认立即拉取订单？</Text>
                        <Text>此操作将会从订单管理系统拉取所有未排线订单以便后续操作；<br />一般而言服务端会每日定期拉取，如确需立即拉取可使用此功能。</Text>
                    </Space>}
                    onConfirm={() => refreshOrderList({
                        from: date.from.format('YYYY-MM-DD'),
                        to: date.to.format('YYYY-MM-DD')
                    }, () => {}, () => setUpdateSign(!updateSign))}
                    okText="确认拉取"
                    cancelText="取消"
                >
                    <Button
                        icon={<SendOutlined />}
                        type='primary'
                        loading={queryLoading}
                    >
                        拉取订单
                    </Button>
                </Popconfirm>
            ]}
        >
            <Content style={{ background: 'white' }}>
                <ProTable
                    search={false}
                    headerTitle={<Space size="large">
                        {`${tabPanelsTitle[Number(tabPanel)]}信息清单`}
                        <Space>
                            {["全部订单", "异常订单"].concat(['1', '4'].includes(tabPanel) ? [tabPanel === '1' ? "未规划订单" : "未签收订单"] : null).map((text, index) => (
                                <CheckableTag
                                    checked={subTabPanel === index}
                                    onChange={() => setSubTabPanel(index)}
                                >
                                    {text}
                                </CheckableTag>
                            ))}
                        </Space>
                    </Space>}
                    options={{
                        fullScreen: true,
                        reload: false,
                        setting: true,
                        density: true
                    }}
                    loading={queryLoading}
                    columns={columns}
                    toolBarRender={() => (
                        <Space size="large">
                            <Text>操作二步验证</Text>
                            <Tooltip title="执行管理操作时是否需要二次确认">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={twoStepVerify}
                                    onChange={checked => setTwoStepVerify(checked)}
                                />
                            </Tooltip>
                            <Tooltip title="搜索指定订单，不限订单状态">
                                <Input.Search
                                    placeholder="订单序列号搜索"
                                    onSearch={value => {
                                        if (value === '') {
                                            message.info("请输入订单序列号！");
                                        }
                                        else {
                                            setDetailOrderId(value);
                                            setDetailVisible(true);
                                        }
                                    }}
                                    style={{ width: 200 }}
                                    allowClear={true}
                                    size="small"
                                />
                            </Tooltip>
                        </Space>
                    )}
                    dataSource={listData?.list}
                    pagination={{
                        position: ["bottomCenter"],
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100],
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        size: "default",
                        total: totalNumber,
                        onChange: (page, size) => {
                            setPageNumber(page);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `第${range[0]}-${range[1]}项 共${total}项`
                    }}
                />
            </Content>
            <OrderInfoDrawer
                visible={detailVisible}
                orderId={detailOrderId}
                refreshParent={() => setUpdateSign(!updateSign)}
                onClose={() => setDetailVisible(false)}
            />
            <OrderPlannedModal
                info={{ id: detailOrderId, waybill: '' }}
                visible={modalVisible}
                onSucceed={() => setUpdateSign(!updateSign)}
                onClose={() => setModalVisible(false)}
            />
        </PageContainer>
    );
}