import React, { useState, useEffect } from 'react';
import { Button, Typography, Space, Statistic, Layout, Tag, message, Tooltip, List, Card, Pagination, Row, Col } from 'antd';
import { ReloadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import moment from 'moment';

import request, { herfRequest } from '../components/request';

const { Paragraph, Text } = Typography;
const { Content } = Layout;

const getDataList = (info, recover, setData) => request(
    '/superuser/overview/tasks', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

const getTaskDetailPage = (taskId) => herfRequest(`/superuser/task_report?taskId=${taskId}`);

const stateRenderer = (state) => {
    switch (state) {
        case 0:
            return <Tag color='blue'>正在排线</Tag>;
        case 1:
            return <Tag color='green'>排线完成</Tag>;
        case 2:
            return <Tag color='red'>排线失败</Tag>;
        default:
            return <Tag color='purple'>未知状态</Tag>;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [tabPanel, setTabPanel] = useState('1');
    const [refreshTime, setRefreshTime] = useState(moment());

    const [listData, setListData] = useState({
        list: Array.from({ length: 30 }, (x, i) => {
            return {
                id: 1000000000 + i,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                ordersNumber: Math.ceil(Math.random() * 100),
                waybillsNumber: Math.ceil(Math.random() * 10),
                vehiclesNumber: Math.ceil(Math.random() * 10),
                state: Math.ceil(Math.random() * 3) - 1 // 0 for waiting, 1 for done, 2 for failed
            }
        }),
        total: 256
    });
    const [queryLoading, setQueryLoading] = useState(false);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            per_page: pageSize,
            page: pageNumber
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    }, [pageNumber, pageSize, updateSign]);

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    本页面展示的数据以加载时为准，<Text strong>并非</Text>自动刷新。要查询当前时刻的最新状态，请点击“<Text strong>刷新</Text>”按钮。
                </Paragraph>
                <Paragraph>
                    下列列表所展示皆为单次排线算法的<Text strong>简要信息</Text>，要获得完整算法执行明细，请点击对应排线任务卡片以<Text strong>下载报告</Text>。
                </Paragraph>
            </Typography>}
            tabList={[
                {
                    tab: "排线历史",
                    key: '1',
                }
            ]}
            subTitle="所有排线任务的执行情况与明细"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            tabActiveKey={tabPanel}
            onTabChange={key => setTabPanel(key)}
            extraContent={
                <Space size="large" style={{ margin: 0 }}>
                    <Statistic title="历史排线任务合计" value={listData?.total} />
                </Space>
            }
            extra={[
                <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => setUpdateSign(!updateSign)}
                    loading={queryLoading}
                >
                    刷新
                </Button>
            ]}
        >
            <Content style={{ background: 'white', padding: 16 }}>
                <List
                    loading={queryLoading}
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={listData.list}
                    renderItem={item => (
                        <List.Item>
                            <Card
                                style={{ cursor: 'default' }}
                                title={(
                                    <Space>
                                        <Text>{item.time}</Text>
                                        {stateRenderer(item.state)}
                                    </Space>
                                )}
                                hoverable={true}
                                extra={(
                                    <Tooltip title='下载排线任务报告'>
                                        <Button
                                            icon={<CloudDownloadOutlined />}
                                            type='text'
                                            onClick={() => getTaskDetailPage(item.id)}
                                        />
                                    </Tooltip>
                                )}
                            >
                                <Row gutter={16}>
                                    <Col span={8}>
                                        <Statistic
                                            title="处理订单数目"
                                            value={item.ordersNumber}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic
                                            title="生成运单数目"
                                            value={item.waybillsNumber}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Statistic
                                            title="使用车辆数目"
                                            value={item.vehiclesNumber}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
                <div style={{ textAlign: 'center' }}>
                    <Pagination
                        showQuickJumper={true}
                        showSizeChanger={true}
                        pageSizeOptions={[20, 50, 100]}
                        defaultCurrent={pageNumber}
                        defaultPageSize={pageSize}
                        size="default"
                        total={totalNumber}
                        onChange={(page, size) => {
                            setPageNumber(page);
                            setPageSize(size);
                        }}
                        showTotal={(total, range) => `第${range[0]}-${range[1]}项 共${total}项`}
                    />
                </div>
            </Content>
        </PageContainer>
    );
}