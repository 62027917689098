import React, { useEffect, useState } from 'react';
import { Typography, Button, Space, Statistic, Layout, Tooltip, message, Tag, Input, Switch, Popconfirm } from 'antd';
import { CheckCircleOutlined, WarningOutlined, DeleteOutlined, FileProtectOutlined, CheckOutlined, CloseOutlined, SettingOutlined, PlusOutlined, UserOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import moment from 'moment';

import request from '../../components/request';
import ModifyDriverModal, { acceptDriver, deleteDriver, setDriverAbnormal, DriverImagesModal, QueryBillModal } from '../../components/driver';

const { Paragraph, Text } = Typography;
const { Content } = Layout;
const { CheckableTag } = Tag;

const getDataList = (info, recover, setData) => request(
    '/superuser/list/drivers', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [tabPanel, setTabPanel] = useState('2'); // Also stands for status.
    const [subTabPanel, setSubTabPanel] = useState(0);

    const [refreshTime, setRefreshTime] = useState(moment());
    const [listData, setListData] = useState({
        statistic: {
            total: 0, // 4096
            unverified: 0, // 2048
            available: 0, // 1024
            processing: 0, // 512
            abnormal: 0 // 2
        },
        list: [] /* Array.from({ length: 3000 }, (x, i) => {
            return {
                id: 1000000000 + i,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                name: '王德发',
                idCardNumber: Math.floor(Math.random() * 100000000000000000),
                phoneNumber: Math.floor(Math.random() * 10000000000),
                // drivingCardNumber: Math.floor(Math.random() * 100000000000000000),
                driverCardNumber: Math.floor(Math.random() * 100000000000000000),
                status: Math.floor(Math.random() * 100) % 3, // 0 for unverified, 1 for available, 2 for processing
                abnormal: Math.random() > 0.8 ? 1 : 0,
                remarks: Math.random() > 0.8 ? "这是测试用备注，无实际意义" : ''
            }
        }) */,
        total: 0 // 256
    });
    const [queryLoading, setQueryLoading] = useState(false);
    const [twoStepVerify, setTwoStepVerify] = useState(true);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);
    const [searchContent, setSearchContent] = useState('');

    const [modifyDriverVisible, setModifyDriverVisible] = useState(false);
    const [currentDriverInfo, setCurrentDriverInfo] = useState({ id: -1 });
    const [driverImagesVisible, setDriverImagesVisible] = useState(false);
    const [queryBillVisible, setQueryBillVisible] = useState(false);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            per_page: pageSize,
            page: pageNumber,
            status: Number(tabPanel),
            constraint: subTabPanel, // Every possible value is for specific constraint per status.
            searchContent: searchContent
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    }, [pageNumber, pageSize, tabPanel, subTabPanel, updateSign, searchContent]);

    const tabPanelsTitle = ['', '审核管理', '状态监控', '自由搜索'];
    const columns = [
        {
            title: '编号',
            dataIndex: 'id',
            copyable: true,
            tooltip: '司机的唯一编号',
            width: '10%'
        },
        {
            title: '注册时间',
            dataIndex: 'time',
            width: '10%'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            width: '5%'
        },
        {
            title: '身份证号',
            dataIndex: 'idCardNumber',
            copyable: true,
            width: '10%'
        },
        {
            title: '手机号',
            dataIndex: 'phoneNumber',
            copyable: true,
            width: '10%'
        },
        {
            title: '驾驶证号',
            dataIndex: 'driverCardNumber',
            copyable: true,
            width: '10%'
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'abnormal',
            render: (_) => (
                <Tag color={_ === 0 ? 'green' : 'red'}>
                    <Space size="small">
                        {_ === 0 ? <CheckCircleOutlined /> : <WarningOutlined />}
                        {_ === 0 ? '正常' : '冻结'}
                    </Space>
                </Tag>
            ),
            width: '5%'
        },
        {
            title: '管理',
            width: '10%',
            render: (_, record) => (
                <Space>
                    <Tooltip title="查看身份信息">
                        <Button
                            icon={<UserOutlined />}
                            onClick={() => {
                                setCurrentDriverInfo(record);
                                setDriverImagesVisible(true);
                            }}
                        />
                    </Tooltip>
                    {record?.status === 0 && <Tooltip title={`确认审核${record?.abnormal !== 0 ? "（请先处理冻结状态）" : ''}`}>
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify || record?.abnormal !== 0}
                            title={<Space direction="vertical">
                                <Text strong>确认通过信息审核？</Text>
                                <Text>通过司机个人信息审核后，将可为该司机分配任务；<br />请务必确认司机个人信息完全真实可信。</Text>
                            </Space>}
                            onConfirm={() => acceptDriver({ driverId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText="通过审核"
                            cancelText="取消"
                        >
                            <Button
                                icon={<FileProtectOutlined />}
                                onClick={() => !twoStepVerify && acceptDriver({ driverId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                                disabled={record?.abnormal !== 0}
                            />
                        </Popconfirm>
                    </Tooltip>}
                    <Tooltip title="修改司机信息">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认进行司机信息修改？</Text>
                                <Text>此功能适用于司机相关信息有更新的场合；<br />请务必核实更新后的司机个人信息真实可信。</Text>
                            </Space>}
                            onConfirm={() => {
                                setCurrentDriverInfo(record);
                                setModifyDriverVisible(true);
                            }}
                            okText="开始修改"
                            cancelText="取消"
                        >
                            <Button
                                icon={<SettingOutlined />}
                                onClick={() => {
                                    if (twoStepVerify) {
                                        return;
                                    }
                                    else {
                                        setCurrentDriverInfo(record);
                                        setModifyDriverVisible(true);
                                    }
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>
                    {record?.status > 0 && <Tooltip title={`${record?.abnormal === 0 ? "冻结司机" : "取消冻结"}`}>
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认变更司机的冻结状态？</Text>
                                <Text>此功能适用于突发情况或者其他需要特殊处理的情形，<br />处于冻结状态下的司机将无法被分配任务。</Text>
                            </Space>}
                            onConfirm={() => setDriverAbnormal({ driverId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText={`${record?.abnormal === 0 ? "冻结司机" : "取消冻结"}`}
                            cancelText="取消"
                        >
                            <Button
                                icon={record?.abnormal === 0 ? <WarningOutlined /> : <CheckCircleOutlined />}
                                onClick={() => !twoStepVerify && setDriverAbnormal({ driverId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                                danger={record?.abnormal === 0}
                                type={record?.abnormal === 0 ? "default" : "primary"}
                            />
                        </Popconfirm>
                    </Tooltip>}
                    <Tooltip title="删除司机">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认删除此司机？</Text>
                                <Text>所有与该司机相关的信息将被删除，请谨慎操作。</Text>
                            </Space>}
                            onConfirm={() => deleteDriver({ driverId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText="确认删除"
                            cancelText="取消"
                            okButtonProps={{ danger: true }}
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                danger={true}
                                onClick={() => !twoStepVerify && deleteDriver({ driverId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    对于新建司机的需求，您可以通过页头右上方的“<Text strong>新建司机</Text>”按钮进行新建操作。
                    新建司机需要提供司机的手机号码，自动生成的密码会通过短信的方式发送至司机手机。
                </Paragraph>
                <Paragraph>
                    在页头下方的标签页中，您可以切换标签页以查看不同状态的司机列表。
                    每次切换标签页都会按照您设定的查询约束向服务器后台进行查询。
                    您可以关注页头上方的蓝色“<Text strong>刷新时间</Text>”标签以判断当前数据的时效性。
                </Paragraph>
            </Typography>}
            tabList={[
                {
                    tab: tabPanelsTitle[1],
                    key: '1',
                },
                {
                    tab: tabPanelsTitle[2],
                    key: '2',
                },
                {
                    tab: tabPanelsTitle[3],
                    key: '3',
                }
            ]}
            subTitle="于本系统注册的所有司机的详细信息"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            tabActiveKey={tabPanel}
            onTabChange={key => {
                setTabPanel(key);
                setSubTabPanel(0);
            }}
            extraContent={
                <Space size="large" style={{ margin: 0 }}>
                    <Statistic title="总司机数" value={listData?.statistic?.total} />
                    <Statistic title="未审核" value={listData?.statistic?.unverified} />
                    <Statistic title="空闲中" value={listData?.statistic?.available} />
                    <Statistic title="任务中" value={listData?.statistic?.processing} />
                    <Statistic title="冻结司机" value={listData?.statistic?.abnormal} />
                </Space>
            }
            extra={[
                <Button
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={() => {
                        setCurrentDriverInfo({ id: -1 });
                        setModifyDriverVisible(true);
                    }}
                    loading={queryLoading}
                >
                    新建司机
                </Button>,
                <Button
                    icon={<SearchOutlined />}
                    onClick={() => setQueryBillVisible(true)}
                    loading={queryLoading}
                >
                    总值查询
                </Button>,
                <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => setUpdateSign(!updateSign)}
                    loading={queryLoading}
                >
                    刷新
                </Button>,
            ]}
        >
            <Content style={{ background: 'white' }}>
                <ProTable
                    search={false}
                    headerTitle={<Space size="large">
                        {`${tabPanelsTitle[Number(tabPanel)]}信息清单`}
                        {tabPanel !== '3' && <Space>
                            {(tabPanel === '1' ? ["待审核司机", "冻结司机"] : ["空闲中司机", "任务中司机", "冻结司机"]).map((text, index) => (
                                <CheckableTag
                                    checked={subTabPanel === index}
                                    onChange={() => setSubTabPanel(index)}
                                >
                                    {text}
                                </CheckableTag>
                            ))}
                        </Space>}
                    </Space>}
                    options={{
                        fullScreen: true,
                        reload: false,
                        setting: true,
                        density: true
                    }}
                    loading={queryLoading}
                    columns={columns}
                    toolBarRender={() => (
                        <Space size="large">
                            <Text>操作二步验证</Text>
                            <Tooltip title="执行管理操作时是否需要二次确认">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={twoStepVerify}
                                    onChange={checked => setTwoStepVerify(checked)}
                                />
                            </Tooltip>
                            <Tooltip title="搜索指定司机，不限司机状态">
                                <Input.Search
                                    placeholder="司机编号/姓名搜索"
                                    onSearch={value => {
                                        if (value === '') {
                                            message.info("请输入司机编号或姓名！");
                                        }
                                        else {
                                            setTabPanel('3');
                                            setSearchContent(value);
                                        }
                                    }}
                                    style={{ width: 200 }}
                                    allowClear={true}
                                    size="small"
                                />
                            </Tooltip>
                        </Space>
                    )}
                    dataSource={listData?.list}
                    pagination={{
                        position: ["bottomCenter"],
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100],
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        size: "default",
                        total: totalNumber,
                        onChange: (page, size) => {
                            setPageNumber(page);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `第${range[0]}-${range[1]}项 共${total}项`
                    }}
                />
            </Content>
            <ModifyDriverModal
                visible={modifyDriverVisible}
                info={currentDriverInfo}
                refreshParent={() => setUpdateSign(!updateSign)}
                onClose={() => setModifyDriverVisible(false)}
            />
            <DriverImagesModal
                visible={driverImagesVisible}
                driverId={currentDriverInfo?.id}
                onClose={() => {
                    setCurrentDriverInfo({ id: -1 });
                    setDriverImagesVisible(false);
                }}
            />
            <QueryBillModal
                visible={queryBillVisible}
                onClose={() => setQueryBillVisible(false)}
            />
        </PageContainer>
    );
}