import { DefaultFooter } from '@ant-design/pro-layout';
import { GithubOutlined } from '@ant-design/icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    return (
        <DefaultFooter
            className={props?.className}
            copyright="2021 SUSTech TMS Development Group"
            links={[
                {
                    key: 'ICP',
                    title: '苏ICP备2020069615号',
                    href: 'https://beian.miit.gov.cn/',
                    blankTarget: true,
                },
                {
                    key: 'github',
                    title: <GithubOutlined />,
                    href: 'https://github.com/EMI-Group',
                    blankTarget: true,
                },
                {
                    key: 'EMI Group',
                    title: 'SUSTech EMI Group',
                    href: 'http://emi.sustech.edu.cn',
                    blankTarget: true,
                }
            ]}
        />
    );
}