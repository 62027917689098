import React, { useEffect, useState } from 'react';
import { Typography, DatePicker, Button, Space, Statistic, Layout, Tooltip, message, Tag, Input, Switch, Popconfirm, Modal } from 'antd';
import { FundViewOutlined, MoneyCollectOutlined, CheckCircleOutlined, WarningOutlined, DeleteOutlined, FormOutlined , CheckOutlined, CloseOutlined, LinkOutlined, PushpinOutlined, PrinterOutlined, UserSwitchOutlined, PlusOutlined, MessageOutlined, FileProtectOutlined, ReloadOutlined, SearchOutlined, RollbackOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import moment from 'moment';

import request, { herfRequest } from '../../components/request';
import ModifyWaybillModal, { ModifyCommentModal, ModifyBillModal, deleteWaybill, acceptWaybill, SummaryModal } from '../../components/waybill';
import { OrderPlannedModal } from '../../components/order';

const { Paragraph, Text } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { CheckableTag } = Tag;

const defaultWaybillInfo = {
    id: '',
    vehicle: {
        number: []
    }
};

const getWaybillDetailPage = (waybillId) => herfRequest(`/waybill/print_waybill?waybillId=${waybillId}`);

const datePair = (from, to) => ({from: from, to: to })

const infoRender = (record) => {
    return {
        waybillId: record?.id,
        driverId: record?.driver?.id,
        vehicleId: record?.vehicle?.id,
        carNumber: record?.vehicle?.number,
        isGreen: record?.vehicle?.green === 1
    };
}

const carNumberDecode = (carNumber) => {
    let first = '', second = '';
    for (let i = 0; i < carNumber?.length; ++i) {
        if (i <= 1) {
            first += String(carNumber[i]);
        }
        else {
            second += String(carNumber[i]);
        }
    }
    return { first, second };
}

const getDataList = (info, recover, setData) => request(
    '/waybill/list/waybills', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [preDate, setPreDate] = useState({ from: moment(), to: moment() });
    const [date, setDate] = useState(preDate);

    const [tabPanel, setTabPanel] = useState('2'); // Also stands for status.
    const [subTabPanel, setSubTabPanel] = useState(0);

    const [refreshTime, setRefreshTime] = useState(moment());
    const [listData, setListData] = useState({
        statistic: {
            total: 0, // 4096,
            unverified: 0, // 2048
            queuing: 0, // 1024
            processing: 0, // 512
            done: 0, // 510
            abnormal: 0 // 2
        },
        list: [] /* Array.from({ length: 3000 }, (x, i) => {
            return {
                key: i,
                id: 1000000000 + i,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                driver: {
                    id: Math.floor(Math.random() * 10000000),
                    name: '王德发'
                },
                vehicle: {
                    id: Math.floor(Math.random() * 1000),
                    number: ['粤', 'B', '1', '2', '3', '4', '5'],
                    type: '长征七号',
                    yellow: Math.random() > 0.8 ? 1 : 0,
                    green: Math.random() > 0.8 ? 1 : 0
                },
                status: Math.floor(Math.random() * 100) % 4,
                abnormal: Math.random() > 0.8 ? 1 : 0,
                orders: Array.from({ length: Math.floor(Math.random() * 10) }, (x, i) => {
                    return {
                        id: 1000000000 + i,
                        time: moment().format('YYYY-MM-DD HH:mm:ss'),
                        merchant: '王德发大酒店',
                        address: `席八大街${i}号`,
                        goods: `奥利给啤酒${Math.ceil(Math.random() * 100)}箱`,
                        abnormal: Math.random() > 0.8 ? 1 : 0
                    }
                }),
                comments: Math.random() > 0.8 ? '歪比巴卜' : '',
                feedback: Math.random() > 0.8 ? '歪比歪比' : '',
                bill: 0
            }
        }) */,
        total: 0 // 256
    });
    const [queryLoading, setQueryLoading] = useState(false);
    const [twoStepVerify, setTwoStepVerify] = useState(true);

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);

    const [searchWaybillId, setSearchWaybillId] = useState('');
    const [searchDriverName, setSearchDriverName] = useState('');
    const [searchDriverId, setSearchDriverId] = useState('');
    const [searchVehicleInfo, setSearchVehicleInfo] = useState('');
    const [searchVehicleId, setSearchVehicleId] = useState('');

    const [modifyWaybillVisible, setModifyWaybillVisible] = useState(false);
    const [modifyWaybillInfo, setModifyWaybillInfo] = useState(defaultWaybillInfo);
    const [commentVisible, setCommentVisible] = useState(false);
    const [billVisible, setBillVisible] = useState(false);

    const [summaryVisible, setSummaryVisible] = useState(false);

    const [modifyOrderVisible, setModifyOrderVisible] = useState(false);
    const [modifyOrderInfo, setModifyOrderInfo] = useState({ id: '', waybill: '' });

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            from: date?.from?.format('YYYY-MM-DD'),
            to: date?.to?.format('YYYY-MM-DD'),
            per_page: pageSize,
            page: pageNumber,
            status: Number(tabPanel),
            constraint: subTabPanel, // Every possible value is for specific constraint per status.
            waybill_id_search: tabPanel === '3' ? searchWaybillId : '',
            driver_name_search: tabPanel === '3' ? searchDriverName : '',
            driver_id_search:  tabPanel === '3' ? searchDriverId : '',
            vehicle_info_search:  tabPanel === '3' ? searchVehicleInfo : '',
            vehicle_id_search:  tabPanel === '3' ? searchVehicleId : '',
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    // NO Dependence on searching keywords.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, pageNumber, pageSize, tabPanel, subTabPanel, updateSign]);

    const tabPanelsTitle = ['', '审核管理', '状态监控', '自由搜索'];
    const columns = [
        {
            title: '序列号',
            dataIndex: 'id',
            copyable: true,
            tooltip: '运单的唯一序列号，用于执行运单与运单的绑定时定位特定运单',
            width: '10%'
        },
        {
            title: '录入时间',
            dataIndex: 'time',
            width: '10%'
        },
        {
            title: '司机编号',
            dataIndex: ['driver', 'id'],
            copyable: true,
            width: '7%'
        },
        {
            title: '司机姓名',
            dataIndex: ['driver', 'name'],
            width: '5%'
        },
        {
            title: '车型编号',
            dataIndex: ['vehicle', 'id'],
            copyable: true,
            width: '7%'
        },
        {
            title: '车牌号',
            dataIndex: ['vehicle', 'number'],
            // copyable: true, // TODO: Having structure problems.
            width: '6%',
            render: (_, record) => (
                <>
                    {_?.length > 0 && <Tag
                        color={record?.vehicle?.yellow === 1 ? "yellow" : record?.vehicle?.green === 1 ? "green" : "blue"}
                    >
                        {carNumberDecode(_).first}
                    </Tag>}
                    {_?.length > 0 && <Tag
                        color={record?.vehicle?.green === 1 ? "green" : record?.vehicle?.yellow === 1 ? "yellow" : "blue"}
                    >
                        {carNumberDecode(_).second}
                    </Tag>}
                    {_?.length === 0 && '-'}
                </>
            )
        },
        {
            title: '车型信息',
            dataIndex: ['vehicle', 'type'],
            ellipsis: true
        },
        {
            title: '价值',
            dataIndex: 'bill',
            width: '5%',
            tooltip: '单位：元（CNY/￥）'
        },
        {
            title: '状态',
            dataIndex: 'abnormal',
            render: (_, record) => (
                <>
                    <Tag color={_ === 0 ? 'green' : 'red'}>
                        <Space size="small">
                            {_ === 0 ? <CheckCircleOutlined /> : <WarningOutlined />}
                            {_ === 0 ? '正常' : '异常'}
                        </Space>
                    </Tag>
                    {record?.comments?.length > 0 && <Tag color="purple">
                        <Space size="small">
                            <PushpinOutlined />
                            {'有注释'}
                        </Space>
                    </Tag>}
                    {record?.feedback?.length > 0 && <Tag color="blue">
                        <Space size="small">
                            <MessageOutlined />
                            {'有反馈'}
                        </Space>
                    </Tag>}
                </>
            ),
            width: '15%'
        },
        {
            title: '管理',
            width: '15%',
            render: (_, record) => (
                <Space size='small'>
                    <Tooltip title="查看排线数据">
                        <Button
                            type='text'
                            icon={<FundViewOutlined />}
                            onClick={() => {
                                setModifyWaybillInfo(record);
                                setSummaryVisible(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="添加或修改注释">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认进行注释改写？</Text>
                                <Text>注释改写适用于需要对运单配送进行微调的情况，<br />相关注释会被推送至承运司机手机端。</Text>
                            </Space>}
                            onConfirm={() => {
                                setModifyWaybillInfo(record);
                                setCommentVisible(true);
                            }}
                            okText="进行改写"
                            cancelText="取消"
                        >
                            <Button
                                type='text'
                                icon={<FormOutlined />}
                                onClick={() => {
                                    if (twoStepVerify) {
                                        return;
                                    }
                                    else {
                                        setModifyWaybillInfo(record);
                                        setCommentVisible(true);
                                    }
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>
                    {record?.status === 0 && <Tooltip title={`通过审核${record?.abnormal !== 0 ? "（请先处理异常）" : ''}`}>
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify || record?.abnormal !== 0}
                            title={<Space direction="vertical">
                                <Text strong>确认通过审核？</Text>
                                <Text>通过审核的运单将正式进入配送流程，<br />运单一经审核通过则不可被撤回。</Text>
                            </Space>}
                            onConfirm={() => acceptWaybill({ waybillId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText="确认通过"
                            cancelText="取消"
                        >
                            <Button
                                type='text'
                                icon={<FileProtectOutlined />}
                                disabled={record?.abnormal !== 0}
                                onClick={() => !twoStepVerify && acceptWaybill({ waybillId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            />
                        </Popconfirm>
                    </Tooltip>}
                    {record?.status === 1 && <Tooltip title="更改运单配置">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认更改运单配置？</Text>
                                <Text>该操作适用于更改运单的承运车型和承运司机等信息，<br />司机分配的更新信息将被推送至相关司机手机端。</Text>
                            </Space>}
                            onConfirm={() => {
                                setModifyWaybillInfo(record);
                                setModifyWaybillVisible(true);
                            }}
                            okText="开始更改"
                            cancelText="取消"
                        >
                            <Button
                                type='text'
                                icon={<UserSwitchOutlined />}
                                onClick={() => {
                                    if (twoStepVerify) {
                                        return;
                                    }
                                    else {
                                        setModifyWaybillInfo(record);
                                        setModifyWaybillVisible(true);
                                    }
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>}
                    {record?.status >= 2 && <Tooltip title="查看反馈">
                        <Button
                            type='text'
                            icon={<MessageOutlined />}
                            disabled={record?.feedback?.length === 0}
                            onClick={() => Modal.info({
                                title: `司机反馈（运单编号：${record?.id}）`,
                                width: 600,
                                okText: "确认",
                                content: record?.feedback
                            })}
                        />
                    </Tooltip>}
                    <Tooltip title="打印运单信息">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认打印运单信息？</Text>
                                <Text>将运单及其附属订单的全部详细信息汇总并打印，<br />该操作将占用一定的服务器性能。</Text>
                            </Space>}
                            onConfirm={() => getWaybillDetailPage(record?.id)}
                            okText="确认打印"
                            cancelText="取消"
                        >
                            <Button
                                type='text'
                                icon={<PrinterOutlined />}
                                onClick={() => !twoStepVerify && getWaybillDetailPage(record?.id)}
                            />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title="修改运单价值">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认修改运单价值？</Text>
                                <Text>运单价值的修改将可能影响后续司机工资的结算；<br />请务必确认确实需要修改后，再谨慎修改这一数值。</Text>
                            </Space>}
                            onConfirm={() => {
                                setModifyWaybillInfo(record);
                                setBillVisible(true);
                            }}
                            okText="开始修改"
                            cancelText="取消"
                        >
                            <Button
                                type='text'
                                icon={<MoneyCollectOutlined />}
                                onClick={() => {
                                    if (twoStepVerify) {
                                        return;
                                    }
                                    else {
                                        setModifyWaybillInfo(record);
                                        setBillVisible(true);
                                    }
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title={`删除运单${record?.abnormal !== 0 ? "（请先处理异常）" : ''}`}>
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify || record?.abnormal !== 0}
                            title={<Space direction="vertical">
                                <Text strong>确认删除此运单？</Text>
                                <Text>所有与该运单相关的信息将被删除，请谨慎操作；<br />该运单挂载的所有订单将被置为“未审核”且“未规划”的状态。</Text>
                            </Space>}
                            onConfirm={() => deleteWaybill({ waybillId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText="确认删除"
                            cancelText="取消"
                            okButtonProps={{ danger: true }}
                        >
                            <Button
                                type='text'
                                disabled={record?.abnormal !== 0}
                                icon={<DeleteOutlined />}
                                danger={true}
                                onClick={() => !twoStepVerify && deleteWaybill({ waybillId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    const ordersRender = (data = { orders: [] }) => {
        const subColumns = [
            {
                title: '序列号',
                dataIndex: 'id',
                copyable: true,
                tooltip: '订单的唯一序列号，用于执行订单与运单的绑定时定位特定订单',
                width: '10%'
            },
            {
                title: '录入时间',
                dataIndex: 'time',
                width: '10%'
            },
            {
                title: '商户名称',
                dataIndex: 'merchant',
                ellipsis: true
            },
            {
                title: '商户地址',
                dataIndex: 'address',
                ellipsis: true
            },
            {
                title: '货品信息',
                dataIndex: 'goods',
                ellipsis: true
            },
            {
                title: '状态',
                dataIndex: 'abnormal',
                render: (_) => (
                    <Tag color={_ === 0 ? 'green' : 'red'}>
                        <Space size="small">
                            {_ === 0 ? <CheckCircleOutlined /> : <WarningOutlined />}
                            {_ === 0 ? '正常' : '异常'}
                        </Space>
                    </Tag>
                ),
                width: '5%'
            },
            {
                title: '管理',
                width: '5%',
                render: (_, record) => (
                    <Tooltip title="手动规划">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify || record?.abnormal !== 0}
                            title={<Space direction="vertical">
                                <Text strong>确认进行手动规划？</Text>
                                <Text>若如此做，您的订单和运单可能将有违约束条件；<br />在此情况下请手动在对应运单中添加注释以进行说明。</Text>
                            </Space>}
                            onConfirm={() => {
                                setModifyOrderInfo({ id: record?.id, waybill: '' });
                                setModifyOrderVisible(true);
                            }}
                            okText="开始规划"
                            cancelText="取消"
                        >
                            <Button
                                disabled={record?.abnormal !== 0}
                                icon={<LinkOutlined />}
                                onClick={() => {
                                    if (twoStepVerify) {
                                        return;
                                    }
                                    else {
                                        setModifyOrderInfo({ id: record?.id, waybill: '' });
                                        setModifyOrderVisible(true);
                                    }
                                }}
                            />
                        </Popconfirm>
                    </Tooltip>
                )
            }
        ];

        return (
            <ProTable
                headerTitle="附属订单列表"
                search={false}
                options={{
                    fullScreen: true,
                    reload: false,
                    setting: true,
                    density: true
                }}
                dataSource={data?.orders}
                pagination={false}
                columns={subColumns}
            />
        );
    };
    
    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    您可以在页面右上角的时间筛选器中选择需要查询的日期区间，默认为查询日当天。
                    此外，在调整中，您也可以点击“<Text strong>重置</Text>”按钮将时间调整回查询日当天。
                    请注意所有对于查询日期区间的调整都必须点击“<Text strong>查询</Text>”（或“<Text strong>刷新</Text>”）按钮方能生效。
                    <br />
                    如有必要，您还可以通过右上角的“<Text strong>新建运单</Text>”按钮创建完全手动排线的运单。
                </Paragraph>
                <Paragraph>
                    在页头下方的标签页中，您可以切换标签页以查看不同状态的运单列表。
                    每次切换标签页都会按照您设定的查询日期区间向服务器后台进行查询。
                    您可以关注页头上方的蓝色“<Text strong>刷新时间</Text>”标签以判断当前数据的时效性。
                </Paragraph>
            </Typography>}
            tabList={[
                {
                    tab: tabPanelsTitle[1],
                    key: '1',
                },
                {
                    tab: tabPanelsTitle[2],
                    key: '2',
                },
                {
                    tab: tabPanelsTitle[3],
                    key: '3',
                }
            ]}
            subTitle="指定时间区间内所有运单的详细信息"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            tabActiveKey={tabPanel}
            onTabChange={key => {
                setTabPanel(key);
                setSubTabPanel(0);
            }}
            extraContent={
                <Space size="large" style={{ margin: 0 }}>
                    <Statistic title="总运单数" value={listData?.statistic?.total} />
                    <Statistic title="未审核" value={listData?.statistic?.unverified} />
                    <Statistic title="待配送" value={listData?.statistic?.queuing} />
                    <Statistic title="配送中" value={listData?.statistic?.processing} />
                    <Statistic title="已完成" value={listData?.statistic?.done} />
                    <Statistic title="异常运单" value={listData?.statistic?.abnormal} />
                </Space>
            }
            extra={[
                <Button
                    icon={<PlusOutlined />}
                    type="dashed"
                    onClick={() => {
                        setModifyWaybillInfo(defaultWaybillInfo);
                        setModifyWaybillVisible(true);
                    }}
                    loading={queryLoading}
                >
                    新建运单
                </Button>,
                <Tooltip title="查询日期区间" placement="bottom">
                    <Text> {/* Using for tooltip */}
                        <RangePicker
                            allowClear={false}
                            placeholder={["起始日期", "截止日期"]}
                            value={[preDate.from, preDate.to]}
                            onChange={dates => setPreDate(datePair(dates[0], dates[1]))}
                        />
                    </Text>
                </Tooltip>,
                <Button
                    icon={<RollbackOutlined />}
                    onClick={() => setPreDate(datePair(moment(), moment()))}
                >
                    重置
                </Button>,
                <Button
                    icon={preDate === date ? <ReloadOutlined /> : <SearchOutlined />}
                    type="primary"
                    onClick={() => preDate === date ? setUpdateSign(!updateSign) : setDate(preDate)}
                    loading={queryLoading}
                >
                    {preDate === date ? '刷新' : '查询'}
                </Button>,
            ]}
        >
            <Content style={{ background: 'white' }}>
                <ProTable
                    search={false}
                    headerTitle={<Space size="large">
                        {`${tabPanelsTitle[Number(tabPanel)]}信息清单`}
                        {tabPanel !== '3' && <Space>
                            {(tabPanel === '1' ? ["待审核运单", "异常运单"] : ["待配送运单", "配送中运单", "已完成运单", "异常运单"]).map((text, index) => (
                                <CheckableTag
                                    checked={subTabPanel === index}
                                    onChange={() => setSubTabPanel(index)}
                                >
                                    {text}
                                </CheckableTag>
                            ))}
                        </Space>}
                        {tabPanel === '3' && <Tooltip title="多关键字搜索，无关的关键字请留空">
                            <Input.Group compact={true}>
                                <Input
                                    style={{ width: 200 }}
                                    placeholder="司机姓名搜索（模糊）"
                                    allowClear={true}
                                    onChange={e => setSearchDriverName(e.target.value)}
                                    value={searchDriverName}
                                    size="small"
                                    onPressEnter={() => setUpdateSign(!updateSign)}
                                />
                                <Input
                                    style={{ width: 200 }}
                                    placeholder="司机编号搜索（精确）"
                                    allowClear={true}
                                    onChange={e => setSearchDriverId(e.target.value)}
                                    value={searchDriverId}
                                    size="small"
                                    onPressEnter={() => setUpdateSign(!updateSign)}
                                />
                                <Input
                                    style={{ width: 200 }}
                                    placeholder="车型信息搜索（模糊）"
                                    allowClear={true}
                                    onChange={e => setSearchVehicleInfo(e.target.value)}
                                    value={searchVehicleInfo}
                                    size="small"
                                    onPressEnter={() => setUpdateSign(!updateSign)}
                                />
                                <Input.Search
                                    style={{ width: 200 }}
                                    placeholder="车型编号搜索（精确）"
                                    onChange={e => setSearchVehicleId(e.target.value)}
                                    value={searchVehicleId}
                                    allowClear={true}
                                    size="small"
                                    onSearch={() => setUpdateSign(!updateSign)}
                                />
                            </Input.Group>
                        </Tooltip>}
                    </Space>}
                    options={{
                        fullScreen: true,
                        reload: false,
                        setting: true,
                        density: true
                    }}
                    loading={queryLoading}
                    columns={columns}
                    expandable={{ expandedRowRender: record => ordersRender(record) }}
                    toolBarRender={() => (
                        <Space size="large">
                            <Text>操作二步验证</Text>
                            <Tooltip title="执行管理操作时是否需要二次确认">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={twoStepVerify}
                                    onChange={checked => setTwoStepVerify(checked)}
                                />
                            </Tooltip>
                            {tabPanel === '3' && <Tooltip title="搜索指定运单，不限运单状态">
                                <Input.Search
                                    placeholder="运单序列号搜索"
                                    onSearch={() => {
                                        if (searchWaybillId === '') {
                                            message.info("请输入运单序列号！");
                                        }
                                        else {
                                            setUpdateSign(!updateSign);
                                        }
                                    }}
                                    style={{ width: 200 }}
                                    onChange={e => setSearchWaybillId(e.target.value)}
                                    value={searchWaybillId}
                                    allowClear={true}
                                    size="small"
                                />
                            </Tooltip>}
                        </Space>
                    )}
                    dataSource={listData?.list}
                    pagination={{
                        position: ["bottomCenter"],
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100],
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        size: "default",
                        total: totalNumber,
                        onChange: (page, size) => {
                            setPageNumber(page);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `第${range[0]}-${range[1]}项 共${total}项`
                    }}
                />
            </Content>
            <ModifyWaybillModal
                visible={modifyWaybillVisible}
                info={infoRender(modifyWaybillInfo)}
                refreshParent={() => setUpdateSign(!updateSign)}
                onClose={() => setModifyWaybillVisible(false)}
            />
            <ModifyCommentModal
                info={modifyWaybillInfo}
                visible={commentVisible}
                onSucceed={() => setUpdateSign(!updateSign)}
                onClose={() => setCommentVisible(false)}
            />
            <OrderPlannedModal
                info={modifyOrderInfo}
                visible={modifyOrderVisible}
                onSucceed={() => setUpdateSign(!updateSign)}
                onClose={() => setModifyOrderVisible(false)}
            />
            <ModifyBillModal
                info={modifyWaybillInfo}
                visible={billVisible}
                onSucceed={() => setUpdateSign(!updateSign)}
                onClose={() => setBillVisible(false)}
            />
            <SummaryModal
                id={modifyWaybillInfo.id}
                visible={summaryVisible}
                onClose={() => setSummaryVisible(false)}
            />
        </PageContainer>
    );
}