import React, { useState } from 'react';
import { message } from 'antd';
import { useMount, useUpdateEffect } from 'ahooks';

import MapboxLanguage from '@mapbox/mapbox-gl-language';
import mapboxgl, { Marker, NavigationControl, Popup } from 'mapbox-gl';
import Minimap from '@aesqe/mapboxgl-minimap';
import { freeRequest } from './request';

const token = 'pk.eyJ1IjoiZXZvaW50ZWNoIiwiYSI6ImNrdTN4aXZzaDAyNXUyb3A1NWRoMzM3MHEifQ.4pmvZmq7n-bmsFjHmzxzzg';
const rtlJsPlugin = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js';

mapboxgl.accessToken = token;
mapboxgl.setRTLTextPlugin(rtlJsPlugin);

const colorList = [
    '#5A477E',
    '#67B850',
    '#E4730A',
    '#F0B02A',
    '#DF3537',
    '#2AE29F',
    '#43BB82',
    '#622B1D',
    '#D85661',
    '#D4D37A',
    '#4FAADC',
    '#750B4E',
    '#F3CE00',
    '#472388',
    '#2ACCCE',
    '#49A237',
    '#2B292D',
    '#B498EB'
];

const getColorHex = index => colorList[index % colorList.length];

const getColorRgb = index => {
    const color = [];
    const res = colorList[index % colorList.length];

    for (let i = 0; i < 3; ++i) {
        color.push(Number(`0x${res.substr(2 * i + 1, 2)}`));
    }

    return color;
};

const getRoutes = (coordinates, setRoutes) => freeRequest(
    `https://api.mapbox.com/directions/v5/mapbox/driving?access_token=${token}`,
    'POST',
    {
        coordinates: coordinates?.map(point => `${point[0]},${point[1]}`).join(';'),
        annotations: 'distance',
        geometries: 'geojson',
        overview: 'full'
    },
    data => data?.code === 'Ok' && setRoutes(data?.routes),
    (error) => message.error("网络错误"),
    () => { }
);

const pulsingDot = (map, size = 100, color = [255, 100, 100]) => ({
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    onAdd: function () {
        let canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
    },

    render: function () {
        let duration = 1000;
        let t = (performance.now() % duration) / duration;

        let radius = size / 2 * 0.3;
        let outerRadius = size / 2 * 0.7 * t + radius;
        let context = this.context;

        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
        context.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]},` + (1 - t) + ')';
        context.fill();

        context.beginPath();
        context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
        context.fillStyle = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`;
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        this.data = context.getImageData(0, 0, this.width, this.height).data;

        map.triggerRepaint();

        return true;
    }
});

const addons = {
    maxIndex: 0,
    markers: [],
    popups: []
};

export default function EvoMap({
    center = [0, 0],
    paths = [],
    refreshData = () => { }
}) {
    const [map, setMap] = useState();
    const [currentCenter, setCurrentCenter] = useState([0, 0]);

    useMount(() => {
        const mapInstance = new mapboxgl.Map({
            container: 'mapbox-container',
            style: 'mapbox://styles/mapbox/streets-v11',
            zoom: 15.5,
            pitch: 45,
            bearing: -17.6,
            center: center,
            dragRotate: true
        });

        mapInstance.addControl(new MapboxLanguage({ defaultLanguage: 'zh-Hans' }));
        mapInstance.addControl(new NavigationControl({ visualizePitch: true }), 'top-left');

        mapInstance.on('load', () => {
            let labelLayerId = null;
            mapInstance.getStyle().layers.forEach(layer => {
                if (layer.type === 'symbol' && layer.layout['text-field']) {
                    labelLayerId = layer.id;
                }
            });

            mapInstance.addLayer({
                id: '3d-buildings',
                source: 'composite',
                'source-layer': 'building',
                filter: ['==', 'extrude', 'true'],
                type: 'fill-extrusion',
                minzoom: 15,
                paint: {
                    'fill-extrusion-color': '#aaa',
                    'fill-extrusion-height': [
                        "interpolate", ["linear"], ["zoom"],
                        15, 0,
                        15.05, ["get", "height"]
                    ],
                    'fill-extrusion-base': [
                        "interpolate", ["linear"], ["zoom"],
                        15, 0,
                        15.05, ["get", "min_height"]
                    ],
                    'fill-extrusion-opacity': .6
                }
            }, labelLayerId);

            mapInstance.addControl(new Minimap({
                style: 'mapbox://styles/mapbox/light-v10',
                center: center,
                zoomLevels: [
                    [18, 0, 14],
                    [14, 0, 10],
                    [10, 0, 6],
                    [6, 0, 2]
                ]
            }), 'top-right');
        });

        setMap(mapInstance);
    });

    useUpdateEffect(() => {
        if (center[0] !== currentCenter[0] || center[1] !== currentCenter[1]) {
            map.flyTo({ center, speed: 1.6 });
        }
        setCurrentCenter(center);
    }, [center]);

    useUpdateEffect(() => {
        if (!map.loaded() || !map.isStyleLoaded()) {
            console.log("LAZY");
            setTimeout(() => refreshData(), 2000);
            return;
        } else {
            console.log("GET");
        }

        addons.markers.forEach(marker => marker.remove());
        addons.markers = [];
        addons.popups.forEach(popup => map.off('click', popup[0], popup[1]));
        addons.popups = [];
        for (let i = 0; i < addons.maxIndex; ++i) {
            map.removeImage(`pulsing-dot-${i}`);
            map.removeLayer(`driver-${i}`);
            map.removeLayer(`routes-${i}`);
            map.removeSource(`driver-${i}`);
            map.removeSource(`routes-${i}`);
        }
        addons.maxIndex = paths.length;

        paths.forEach((path, index) => {
            path.routes.forEach((route, innerIndex) => {
                const popup = new Popup({
                    closeButton: false,
                    anchor: 'bottom',
                    offset: 60
                });

                popup.setHTML(
                    `<div>
                        <strong>第${innerIndex}目的地：${route.name}</strong>
                    </div>
                    <div>${route.description}</div>`)
                    .setLngLat(route.position)
                    .addTo(map);

                addons.markers.push(new Marker({
                    anchor: 'bottom',
                    color: getColorHex(index)
                }).setLngLat(route.position)
                    .setPopup(popup)
                    .addTo(map));
            });

            map.addImage(
                `pulsing-dot-${index}`,
                pulsingDot(map, 100, getColorRgb(index)),
                { pixelRatio: 2 }
            );

            map.addLayer({
                id: `driver-${index}`,
                type: "symbol",
                source: {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [{
                            type: "Feature",
                            geometry: {
                                type: "Point",
                                coordinates: path.driver.position
                            }
                        }]
                    }
                },
                layout: {
                    "icon-image": `pulsing-dot-${index}`
                }
            });

            const popup = new Popup({
                closeButton: false,
                offset: 20
            }).setHTML(
                `<div>
                    <strong>司机：${path.driver.name}</strong>
                </div>
                <div>驾驶车型：${path.driver.vehicleType}</div>
                <div>车辆牌照：${path.driver.carNumber}</div>
                <div>联系方式：${path.driver.phoneNumber}</div>`)
                .setLngLat(path.driver.position);

            const showPopup = () => popup.addTo(map);
            addons.popups.push([`driver-${index}`, showPopup]);

            map.on('click', `driver-${index}`, showPopup);

            map.on('mouseenter', `driver-${index}`, () => map.getCanvas().style.cursor = 'pointer');
            map.on('mouseleave', `driver-${index}`, () => map.getCanvas().style.cursor = '');

            getRoutes(
                path.routes.map(route => route.position),
                routes => {
                    map.addLayer({
                        id: `routes-${index}`,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: routes[0]?.geometry
                        },
                        layout: {
                            'line-cap': 'round',
                            'line-join': 'round'
                        },
                        paint: {
                            'line-width': 5,
                            'line-color': getColorHex(index),
                            'line-blur': 1
                        }
                    }, `driver-${index}`);
                }
            );
        });

        setTimeout(() => refreshData(), 10000);
    }, [paths]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <link href='https://api.tiles.mapbox.com/mapbox-gl-js/v1.1.1/mapbox-gl.css' rel='stylesheet' />
            <div id='mapbox-container' style={{ height: '100%' }} />
        </div>
    );
}