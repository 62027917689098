import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Tabs, Image, Typography, Layout, message, Spin } from 'antd';
import ProForm, { ProFormText, ProFormCaptcha } from '@ant-design/pro-form';
import { MobileOutlined, MailOutlined, WarningOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import Footer from '../components/footer';

import '../less/login.less';

import LogoImg from '../assets/icons/Logo.png';
import { useTitle, useSessionStorageState, useMount } from 'ahooks';

import request from '../components/request';

const { Content } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const waitTime = (time = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

const userLogin = (info, recover, history, setData) => {
    request('/superuser/login', 'POST', info,
        (data) => {
            if (data?.status === 1) {
                message.success("登录成功，将跳转至系统界面");
                setData({ level: 3, name: info?.username });
                setTimeout(() => history.push('/'), 1000);
            }
            else {
                message.warning(data?.info);
            }
        },
        (error) => message.error("网络错误"),
        recover
    );
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    useTitle('用户登录 - SUSTech TMS')

    const history = useHistory();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [userInfo, setUserInfo] = useSessionStorageState("userInfo",  { defaultValue: { level: 0, name: '' } });

    useMount(() => {
        if (userInfo?.level > 0) {
            message.warning("您已经登录！如果需要使用另一账号登录，请先注销");
            history.push('/');
        }
    });

    return (
        <Layout
            className="background"
            style={{
                height: '100vh',
                paddingTop: '15vh'
            }}
        >
            <Content
                style={{
                    width: 400,
                    margin: 'auto'
                }}
            >
                <Content
                    style={{
                        textAlign: 'center',
                        marginBottom: 16
                    }}
                >
                    <Content
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            marginBottom: 8
                        }}
                    >
                        <Image
                            alt="logo"
                            src={LogoImg}
                            preview={false}
                            height='44px'
                            width='44px'
                        />
                        <Title
                            level={2}
                            style={{
                                textAlign: 'center',
                                margin: '0 0 4px 16px'
                            }}
                        >
                            SUSTech TMS
                        </Title>
                    </Content>
                    <Text>
                        SUSTech Transportation Management System
                    </Text>
                </Content>
                <Tabs defaultActiveKey="account" centered>
                    <TabPane tab="账号密码登录" key="account">
                        <ProForm
                            onFinish={(values) => {
                                setSubmitLoading(true);
                                userLogin(values, () => setSubmitLoading(false), history, setUserInfo);
                            }}
                            submitter={{
                                searchConfig: {
                                    submitText: '登录',
                                },
                                render: (_, dom) => dom.pop(),
                                submitButtonProps: {
                                    size: 'large',
                                    style: {
                                        width: '100%',
                                    },
                                    loading: submitLoading
                                },
                            }}
                        >
                            <ProFormText
                                fieldProps={{
                                    size: 'large',
                                    prefix: <UserOutlined />,
                                }}
                                name="username"
                                placeholder="请输入用户名"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名！',
                                    },
                                    {
                                        pattern: /^$|/,
                                        message: '不合法的用户名格式！',
                                    },
                                ]}
                                
                            />
                            <ProFormText.Password
                                fieldProps={{
                                    size: 'large',
                                    prefix: <KeyOutlined />,
                                }}
                                name="password"
                                placeholder="请输入密码"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码！',
                                    },
                                    {
                                        pattern: /^[0-9A-Za-z]{6,}$/,
                                        message: '密码长度应大于6位，且由数字和字母组成',
                                    },
                                ]}
                            />
                        </ProForm>
                    </TabPane>
                    <TabPane tab="手机验证码登录" key="phone">
                        <Spin indicator={<WarningOutlined />} tip="此功能暂未开放">
                            <ProForm
                                onFinish={async () => {
                                    await waitTime(2000);
                                    message.success('提交成功');
                                }}
                                submitter={{
                                    searchConfig: {
                                        submitText: '登录',
                                    },
                                    render: (_, dom) => dom.pop(),
                                    submitButtonProps: {
                                        size: 'large',
                                        style: {
                                            width: '100%',
                                        },
                                    },
                                }}
                            >
                                <ProFormText
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <MobileOutlined />,
                                    }}
                                    name="phone"
                                    placeholder="请输入手机号"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入手机号！',
                                        },
                                        {
                                            pattern: /^1\d{10}$/,
                                            message: '不合法的手机号格式！',
                                        },
                                    ]}
                                />
                                <ProFormCaptcha
                                    fieldProps={{
                                        size: 'large',
                                        prefix: <MailOutlined />,
                                    }}
                                    captchaProps={{
                                        size: 'large',
                                    }}
                                    phoneName="phone"
                                    name="captcha"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入验证码',
                                        },
                                    ]}
                                    placeholder="请输入验证码"
                                    onGetCaptcha={async (phone) => {
                                        await waitTime(1000);
                                        message.success(`手机号 ${phone} 验证码发送成功！`);
                                    }}
                                />
                            </ProForm>
                        </Spin>
                    </TabPane>
                </Tabs>
            </Content>
            <Footer className="footer" />
        </Layout>
    );
}