import React, { useEffect, useState } from 'react';
import { Form, Typography, Button, Space, Statistic, Layout, Tooltip, message, Tag, Input, Switch, Popconfirm } from 'antd';
import { DeleteOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import moment from 'moment';

import request from '../../components/request';
import VehicleDetailDrawer, { ModifyVehicleModal, deleteVehicle } from '../../components/vehicle';

const { Paragraph, Text } = Typography;
const { Content } = Layout;

const getDataList = (info, recover, setData) => request(
    '/superuser/list/vehicles', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
);

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const [refreshTime, setRefreshTime] = useState(moment());
    const [listData, setListData] = useState({
        statistic: {
            total: 0 // 4096
        },
        list: [] /* Array.from({ length: 3000 }, (x, i) => {
            return {
                id: 1000000000 + i,
                time: moment().format('YYYY-MM-DD HH:mm:ss'),
                name: '长征七号',
                volume: String(Math.floor(Math.random() * 100)),
                weight: String(Math.floor(Math.random() * 100)),
                remarks: Math.random() > 0.8 ? "这是测试用备注，无实际意义" : '',
                isGreen: Math.random() > 0.8 ? 1 : 0,
                isYellow: Math.random() > 0.8 ? 1 : 0,
                amount: Math.floor(Math.random() * 100)
            }
        }) */,
        total: 0 // 256
    });
    const [queryLoading, setQueryLoading] = useState(false);
    const [twoStepVerify, setTwoStepVerify] = useState(true);
    const [searchContent, setSearchContent] = useState('');

    const [currentVehicleId, setCurrentVehicleId] = useState();
    const [detailVisible, setDetailVisible] = useState(false);
    const [modifyVisible, setModifyVisible] = useState(false);
    const [formHook] = Form.useForm();

    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalNumber, setTotalNumber] = useState(0);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        setQueryLoading(true);
        getDataList({
            per_page: pageSize,
            page: pageNumber,
            searchContent: searchContent
        }, () => setQueryLoading(false),
            data => {
                setListData(data);
                setTotalNumber(data?.total);
                setRefreshTime(moment());
            }
        );
    }, [pageNumber, pageSize, searchContent, updateSign]);

    const columns = [
        {
            title: '编号',
            dataIndex: 'id',
            copyable: true,
            tooltip: '车型的唯一编号',
            width: '10%'
        },
        {
            title: '录入时间',
            dataIndex: 'time',
            width: '10%'
        },
        {
            title: '车型名称',
            dataIndex: 'name',
            ellipsis: true,
            width: '15%'
        },
        {
            title: '货厢容积',
            dataIndex: 'volume',
            tooltip: '单位：立方米（m^3）',
            width: '5%'
        },
        {
            title: '额定载重',
            dataIndex: 'weight',
            tooltip: '单位：千克/公斤（kg）',
            width: '5%'
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            ellipsis: true
        },
        {
            title: '入库数目',
            dataIndex: 'amount',
            width: '5%'
        },
        {
            title: '车牌信息',
            render: (_, record) => (
                <Space>
                    <Tag
                        color={record?.isYellow === 1 ? 'yellow' : 'blue'}
                        style={{ margin: 0 }}
                    >
                        {record?.isYellow === 1 ? "大型车" : "小型车"}
                    </Tag>
                    {record?.isGreen === 1 && <Tag color="green" style={{ margin: 0 }}>新能源</Tag>}
                </Space>
            ),
            width: '10%'
        },
        {
            title: '管理',
            width: '5%',
            render: (_, record) => (
                <Space>
                    <Tooltip title="查看车型详细信息">
                        <Button
                            icon={<InfoCircleOutlined />}
                            onClick={() => {
                                setCurrentVehicleId(record?.id);
                                setDetailVisible(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="删除车型">
                        <Popconfirm
                            placement="bottomRight"
                            disabled={!twoStepVerify}
                            title={<Space direction="vertical">
                                <Text strong>确认删除此司机？</Text>
                                <Text>所有与该司机相关的信息将被删除，请谨慎操作；<br />该运单挂载的所有订单将被置为“未审核”且“为规划”的状态。</Text>
                            </Space>}
                            onConfirm={() => deleteVehicle({ vehicleId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            okText="确认删除"
                            cancelText="取消"
                            okButtonProps={{ danger: true }}
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                danger={true}
                                onClick={() => !twoStepVerify && deleteVehicle({ vehicleId: record?.id }, () => {}, () => setUpdateSign(!updateSign))}
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <PageContainer
            content={<Typography>
                <Paragraph>
                    对于录入新车型的需求，您可以通过页头右上方的“<Text strong>新建车型</Text>”按钮进行新建操作。新建车型需要提供部分基本信息，额外信息可自选提供。
                </Paragraph>
                <Paragraph>
                    本页面的搜索功能并未使用新的搜索标签页，如需清空搜索约束请删除搜索框内的全部文本后<Text strong>点击搜索按钮</Text>或者在搜索框内<Text strong>按下回车键</Text>以清空约束。
                    您可以关注页头上方的蓝色“<Text strong>刷新时间</Text>”标签以判断当前数据的时效性。
                </Paragraph>
            </Typography>}
            subTitle="于本系统注册的所有车型的详细信息"
            tags={<Tag color="blue">{`上次刷新：${refreshTime.format('YYYY-MM-DD HH:mm:ss')}`}</Tag>}
            extraContent={<Statistic title="总车型数" value={listData?.statistic?.total} />}
            extra={[
                <Button
                    icon={<PlusOutlined />}
                    type="dashed"
                    loading={queryLoading}
                    onClick={() => setModifyVisible(true)}
                >
                    新建车型
                </Button>,
                <Button
                    icon={<ReloadOutlined />}
                    type="primary"
                    onClick={() => setUpdateSign(!updateSign)}
                    loading={queryLoading}
                >
                    刷新
                </Button>,
            ]}
        >
            <Content style={{ background: 'white' }}>
                <ProTable
                    search={false}
                    headerTitle="车型信息清单"
                    options={{
                        fullScreen: true,
                        reload: false,
                        setting: true,
                        density: true
                    }}
                    loading={queryLoading}
                    columns={columns}
                    toolBarRender={() => (
                        <Space size="large">
                            <Text>操作二步验证</Text>
                            <Tooltip title="执行管理操作时是否需要二次确认">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={twoStepVerify}
                                    onChange={checked => setTwoStepVerify(checked)}
                                />
                            </Tooltip>
                            <Tooltip title="搜索指定车型">
                                <Input.Search
                                    placeholder="车型编号/名称搜索"
                                    onSearch={value => setSearchContent(value)}
                                    style={{ width: 200 }}
                                    allowClear={true}
                                    size="small"
                                />
                            </Tooltip>
                        </Space>
                    )}
                    dataSource={listData?.list}
                    pagination={{
                        position: ["bottomCenter"],
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: [20, 50, 100],
                        defaultCurrent: pageNumber,
                        defaultPageSize: pageSize,
                        size: "default",
                        total: totalNumber,
                        onChange: (page, size) => {
                            setPageNumber(page);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `第${range[0]}-${range[1]}项 共${total}项`
                    }}
                />
            </Content>
            <VehicleDetailDrawer
                refreshParent={() => setUpdateSign(!updateSign)}
                visible={detailVisible}
                vehicleId={currentVehicleId}
                onClose={() => setDetailVisible(false)}
            />
            <ModifyVehicleModal
                formHook={formHook}
                vehicleId={-1}
                visible={modifyVisible}
                onSucceed={() => setUpdateSign(!updateSign)}
                onClose={() => setModifyVisible(false)}
            />
        </PageContainer>
    );
}