import React, { useEffect, useState } from 'react';
import { Form, message, Space, Drawer, Button, Layout, Spin, Typography, Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import ProDescriptions from '@ant-design/pro-descriptions';
import { ModalForm, ProFormText, ProFormDigit, ProFormSwitch } from '@ant-design/pro-form';

import moment from 'moment';
import request from './request';

const { Content } = Layout;
const { Paragraph } = Typography;

const getVehicleInfo = (info, recover, setData, onFailed) => request(
    '/superuser/get/vehicle_detail', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            setData(data);
        }
        else {
            message.warning(data?.info);
            onFailed();
        }
    },
    (error) => {
        message.error("网络错误");
        onFailed();
    },
    recover
)

const setVehicleInfo = (create = false, info, onSucceed) => request(
    '/superuser/set/vehicle_detail', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success(create ? "新建车型成功" : "修改车型信息成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => {
        message.error("网络错误");
    },
    () => {}
)

export const ModifyVehicleModal = ({ formHook, vehicleId, visible, info, onSucceed, onClose }) => (
    <ModalForm
        title={vehicleId === -1 ? "新建车型" : `修改车型信息（车型编号：${vehicleId}）`}
        visible={visible}
        form={formHook}
        layout="horizontal"
        initialValues={info}
        onVisibleChange={() => formHook.resetFields(Object.keys(info ? info : {}))}
        labelAlign="left"
        labelCol={{ span: 6 }}
        onFinish={values => setVehicleInfo(
            vehicleId === -1,
            {
                vehicleId: vehicleId,
                ...values
            },
            () => {
                onSucceed();
                onClose();
            }
        )}
        modalProps={{
            onCancel: onClose
        }}
        colon={false}
    >
        <ProFormText
            name="name"
            label="车型名称"
            placeholder="请输入车型名称"
            required={true}
        />
        <ProFormDigit
            name="outerLength"
            label="车长"
            tooltip="单位：米（m）"
            placeholder="请输入该车型的外侧测量长度"
            required={true}
        />
        <ProFormDigit
            name="outerWidth"
            label="车宽"
            tooltip="单位：米（m）"
            placeholder="请输入该车型的外侧测量宽度"
            required={true}
        />
        <ProFormDigit
            name="outerHeight"
            label="车高"
            tooltip="单位：米（m）"
            placeholder="请输入该车型的外侧测量高度"
            required={true}
        />
        <ProFormDigit
            name="innerVolume"
            label="货厢容积"
            tooltip="单位：立方米（m^3）"
            placeholder="请输入该车型的货物装载区域最大容积"
            required={true}
        />
        <ProFormDigit
            name="innerLength"
            label="货厢长"
            tooltip="单位：米（m）"
            placeholder="请输入该车型的货物装载区域测量长度"
            required={true}
        />
        <ProFormDigit
            name="innerWidth"
            label="货厢宽"
            tooltip="单位：米（m）"
            placeholder="请输入该车型的货物装载区域测量宽度"
            required={true}
        />
        <ProFormDigit
            name="innerHeight"
            label="货厢高"
            tooltip="单位：米（m）"
            placeholder="请输入该车型的货物装载区域测量高度"
            required={true}
        />
        <ProFormDigit
            name="selfWeight"
            label="车身自重"
            tooltip="单位：千克/公斤（kg）"
            placeholder="请输入该车型的空载自重"
            required={true}
        />
        <ProFormDigit
            name="loadWeight"
            label="最大核载重量"
            tooltip="单位：千克/公斤（kg）"
            placeholder="请输入该车型的最大核载货物重量"
            required={true}
        />
        <ProFormDigit
            name="axis"
            label="车辆轴数"
            placeholder="请输入该车型的轴数"
            required={true}
        />
        <ProFormDigit
            name="amount"
            label="入库数量"
            placeholder="请输入该车型目前登记在册的车辆数目"
            required={true}
        />
        <ProFormSwitch
            name="isYellow"
            label="车型大小"
            normalize={selected => selected === true ? 1 : 0}
            fieldProps={{
                unCheckedChildren: "小型车",
                checkedChildren: "大型车"
            }}
        />
        <ProFormSwitch
            name="isGreen"
            label="动力模式"
            normalize={selected => selected === true ? 1 : 0}
            fieldProps={{
                unCheckedChildren: "传统型",
                checkedChildren: "新能源"
            }}
        />
        <ProFormText
            name="remarks"
            label="备注"
            placeholder="其他对于此车型的描述文本，可留空"
        />
    </ModalForm>
);

export const deleteVehicle = (info, recover, onSucceed) => request(
    '/superuser/delete_vehicle', 'POST', info,
    (data) => {
        if (data?.status === 1) {
            message.success("删除车型成功");
            onSucceed();
        }
        else {
            message.warning(data?.info);
        }
    },
    (error) => message.error("网络错误"),
    recover
)

const TipLabel = ({ title, tip }) => (
    <Space>
        {title}
        <Tooltip title={tip}>
            <InfoCircleOutlined />
        </Tooltip>
    </Space>
)

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ refreshParent, visible, vehicleId, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [formHook] = Form.useForm();
    const [info, setInfo] = useState({
        id: 1001,
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        name: '长征七号',
        outerLength: 0,
        outerWidth: 0,
        outerHeight: 0,
        innerVolume: 0,
        innerLength: 0,
        innerWidth: 0,
        innerHeight: 0,
        selfWeight: 0,
        loadWeight: 0,
        axis: 0,
        amount: 1,
        isGreen: 1,
        isYellow: 1,
        remarks: '歪比巴卜'
    });

    const [modifyVisible, setModifyVisible] = useState(false);

    const [updateSign, setUpdateSign] = useState(false); // Using for forcing refresh.
    useEffect(() => {
        if (vehicleId === -1 || visible === false) {
            return;
        }
        else {
            setLoading(true);
            getVehicleInfo({ id: vehicleId }, () => setLoading(false), setInfo, onClose);
        }
    }, [onClose, vehicleId, visible, updateSign]);

    return (
        <Drawer
            visible={visible}
            onClose={onClose}
            width='40%'
            title={`车型详细信息（序列号：${vehicleId}）`}
            footer={<Content style={{ textAlign: 'right' }}>
                <Space>
                    <Button
                        loading={loading}
                        onClick={onClose}
                    >
                        确认
                    </Button>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => setModifyVisible(true)}
                    >
                        编辑
                    </Button>
                </Space>
            </Content>}
        >
            <Spin
                size="large"
                spinning={loading}
                tip="加载中……"
            >
                <ProDescriptions
                    bordered={true}
                    column={1}
                    loading={loading}
                >
                    <ProDescriptions.Item label="车型编号">
                        <Paragraph
                            copyable={{
                                text: info?.id,
                                tooltips: ["复制车型编号", "复制成功"]
                            }}
                            style={{ margin: 0 }}
                        >
                            {info?.id}
                        </Paragraph>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="录入时间">{info?.time}</ProDescriptions.Item>
                    <ProDescriptions.Item label="车型名称">{info?.name}</ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="车长" tip="单位：米（m）"/>}
                    >
                        {info?.outerLength}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="车宽" tip="单位：米（m）"/>}
                    >
                        {info?.outerWidth}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="车高" tip="单位：米（m）"/>}
                    >
                        {info?.outerHeight}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="货厢容积" tip="单位：立方米（m^3）"/>}
                    >
                        {info?.innerVolume}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="货厢长" tip="单位：米（m）"/>}
                    >
                        {info?.innerLength}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="货厢宽" tip="单位：米（m）"/>}
                    >
                        {info?.innerWidth}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="货厢高" tip="单位：米（m）"/>}
                    >
                        {info?.innerHeight}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="车身自重" tip="单位：千克/公斤（kg）"/>}
                    >
                        {info?.selfWeight}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        label={<TipLabel title="最大核载重量" tip="单位：千克/公斤（kg）"/>}
                    >
                        {info?.loadWeight}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="车辆轴数">{info?.axis}</ProDescriptions.Item>
                    <ProDescriptions.Item label="入库数量">{info?.amount}</ProDescriptions.Item>
                    <ProDescriptions.Item label="车牌信息">
                        <Space>
                            <Tag
                                color={info?.isYellow === 1 ? 'yellow' : 'blue'}
                                style={{ margin: 0 }}
                            >
                                {info?.isYellow === 1 ? "大型车" : "小型车"}
                            </Tag>
                            {info?.isGreen === 1 && <Tag color="green" style={{ margin: 0 }}>新能源</Tag>}
                        </Space>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="备注">{info?.remarks}</ProDescriptions.Item>
                </ProDescriptions>
            </Spin>
            <ModifyVehicleModal
                formHook={formHook}
                vehicleId={vehicleId}
                visible={modifyVisible}
                info={info}
                onSucceed={() => {
                    refreshParent();
                    setUpdateSign(!updateSign);
                }}
                onClose={() => setModifyVisible(false)}
            />
        </Drawer>
    );
}